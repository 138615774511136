const Library = () => import("@/views/public/library/Library.vue");

const InTheNews = () => import("@/views/public/library/news/NewsPage.vue");
const SpecificNewsPage = () => import("@/views/public/library/news/SpecificNewsPage.vue");

const PressReleases = () => import("@/views/public/library/pressreleases/PressReleasesPage.vue");
const SpecificPressReleasePage = () =>
  import("@/views/public/library/pressreleases/SpecificPressReleasePage.vue");

const Memoriam = () => import("@/views/public/library/memoriam/Memoriam.vue");
const LooseThreads = () => import("@/views/public/library/looseThreads/LooseThreads.vue");

const Acquisitions = () => import("@/views/public/library/Acquisitions.vue");

const Arttextstyle = () => import("@/views/public/library/arttextstyle/Arttextstyle.vue");
const Blog = () => import("@/views/public/library/arttextstyle/Blog.vue");

export default [
  {
    path: "/media",
    name: "Media",
    component: Library,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/media/in-the-news",
    name: "InTheNews",
    component: InTheNews,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/media/in-the-news/:slug",
    name: "SpecificNews",
    component: SpecificNewsPage,
    meta: {
      layout: "base",
      shareButton: true,
    },
  },
  {
    path: "/media/press-releases",
    name: "PressReleases",
    component: PressReleases,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/media/press-releases/:slug",
    name: "SpecificPressReleasePage",
    component: SpecificPressReleasePage,
    meta: {
      layout: "base",
      shareButton: true,
    },
  },
  {
    path: "/media/loose-threads",
    name: "LooseThreads",
    component: LooseThreads,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/media/lives-well-lived",
    name: "Memoriam",
    component: Memoriam,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/media/acquisitions",
    name: "Acquisitions",
    component: Acquisitions,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/media/arttextstyle",
    name: "ArttextstylePublic",
    component: Arttextstyle,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/media/arttextstyle/:slug",
    name: "Blog",
    component: Blog,
    meta: {
      layout: "base",
      shareButton: true,
    },
  },
];
