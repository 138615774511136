import Vue from "vue";
export default {
    namespaced: true,
    state: {
        loading: false,
        arttextstyles: [],
        featuredPost: {
            slug: null
        },
        hasMorePages: false,
        arttextstyle: {
            comments: []
        },
        prevNextSlug: {
            next: false,
            prev: false
        },
        threeRandomPosts: [],
        filters: [],
        commentLoading: false
    },
    getters: {
        getArttextstyles(state) { return state.arttextstyles; },
        getFeaturedPost(state) { return state.featuredPost; },
        getLoading(state) { return state.loading; },
        getHasMorePages(state) { return state.hasMorePages; },
        getArttextstyle(state) { return state.arttextstyle; },
        getFilters(state) { return state.filters },
        getPrevNextSlug(state) { return state.prevNextSlug },
        getThreeRandomPosts(state) { return state.threeRandomPosts },
        getCommentLoading(state) { return state.commentLoading },
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload;
        },
        setArttextstyles(state, { data, page }) {
            if (page > 1) {
                state.arttextstyles = state.arttextstyles.concat(data);
            } else {
                state.arttextstyles = data;
            }
        },
        setHasMorePages(state, payload) {
            state.hasMorePages = payload;
        },
        setFeaturedPost(state, payload) {
            state.featuredPost = { ...payload, slug: payload.slug ? payload.slug : null }
        },
        setArttextstyle(state, payload) {
            state.arttextstyle = payload
        },
        setPrevNextSlug(state, payload) {
            state.prevNextSlug = payload
        },
        setThreeRandomPosts(state, payload) {
            state.threeRandomPosts = payload
        },
        setFilters(state, payload) {
            let filters = [
                {
                    id: '',
                    title: 'ALL ARTTEXTSTYLE',
                    selected: true
                }
            ];
            payload.forEach(el => {
                filters.push({ ...el, selected: el.selected ? el.selected : false })
            });
            state.filters = filters
        },
        changeFilterValue(state, payload) {
            let filters = state.filters;
            filters.forEach(el => {
                el.selected = el.id === payload.id ? true : false;
            })
            state.filters = filters
        },
        setCommentLoading(state, payload) {
            state.commentLoading = payload
        },
    },
    actions: {
        async apiGetArttextstyles({ commit }, { tags = '', sort = "newest_to_oldest ", page = "1" }) {
            let success = false;
            commit("setLoading", true);
            commit("setHasMorePages", false);
            let query = {
                tags,
                sort,
                page,
            };
            let queryStr = "";
            for (let key in query) {
                if (query[key]) {
                    queryStr += `${key}=${query[key]}&`;
                }
            }
            await Vue.axios
                .get(`/api/public/arttextstyles?${queryStr}`)
                .then(res => res.data)
                .then(data => {
                    success = true;
                    commit("setArttextstyles", { data: data.response, page });
                    commit("setHasMorePages", data.hasMorePages);
                    commit('setFeaturedPost', data.featuredPost)
                })
                .finally(() => {
                    commit("setLoading", false);
                });
            return success;
        },

        async apiGetSpecificArttextstyle({ commit }, slug) {
            await Vue.axios.get(`/api/public/arttextstyles/${slug}`)
                .then(response => {
                    let PrevNextSlug = {
                        next: response?.data.nextPostSlug || false,
                        prev: response?.data.previousPostSlug || false,
                    }
                    commit("setArttextstyle", response?.data.post ?? {});
                    commit('setPrevNextSlug', PrevNextSlug)
                    commit('setThreeRandomPosts', response?.data.threeRandomPosts)
                });
        },

        async apiGetFiltersBlock({ commit },) {
            await Vue.axios.get(`/api/public/tags/arttextstyle`)
                .then((res) => res.data)
                .then((data) => {
                    commit('setFilters', data?.response)
                })
        },
        async apiPostComment({ commit }, payload) {
            let success = false
            commit('setCommentLoading', true)
            await Vue.axios.post(`/api/public/comments`, payload)
                .then((res) => res.data)
                .then((data) => {
                    success = true
                    Vue.toasted.show(data.message, {
                        duration: 2000,
                        type: "success",
                    });
                })
                .finally(() => {
                    commit('setCommentLoading', false)
                })
            return success
        },
    },
};
