<template>
  <transition name="search__fade">
    <div class="search" v-if="searchActive">
      <div class="header">
        <Logo class="logo"></Logo>
        <div class="menu">
          <IconButton class="menu__button button--lg button--icon-white" @click="$emit('showMenu')">
            <IconMenu></IconMenu>
          </IconButton>
          <IconButton
            class="menu__close button--md button--outline-white"
            @click="$emit('hidenSearch')"
          >
            <IconClose width="18" height="18"></IconClose>
          </IconButton>
        </div>
      </div>
      <div
        class="search__inner"
        :class="{
          'search__inner--search-active':
            (searchResult.artworks.list.length > 0 ||
            searchResult.artists.list.length > 0 ||
            searchResult.articles.list.length > 0 ||
            searchResult.events.list.length > 0),
        }"
      >
        <div class="search__input-container">
          <BaseInput
            class="search__input"
            :element="search"
            placeholder="Type to search"
            @input="event => changeInput(event)"
            @keyup.enter.native="inputSearch"
          ></BaseInput>
          <BaseButton
            class="search__button button--outline-white button--sm"
            text="Search"
            @click="inputSearch"
          ></BaseButton>
        </div>
        <p class="search__mesage text-sub-title" v-if="!search && !searchResult.result">
          Try for example "Exhibitions" or "Baskets"
        </p>
        <p class="search__mesage text-sub-title" v-if="loading && search">Searching...</p>
        <p
          class="search__mesage text-sub-title"
          v-if="!loading && search && searched && !searchResult.result"
        >
          No Results
        </p>
        <template
          v-if="
            (searchResult.artworks.list.length > 0 ||
              searchResult.artists.list.length > 0 ||
              searchResult.articles.list.length > 0 ||
              searchResult.events.list.length > 0) &&
            !loading
          "
        >
          <div class="search__result-nav">
            <template v-for="(item, index) in nav">
              <BaseButton
                class="search__result-nav-button"
                v-if="item.count > 0"
                :key="`nav-${index}`"
                :class="{ 'button--white': item.active, 'button--outline-white': !item.active }"
                :text="`${item.text} (${item.count})`"
                @click="clickNav(item.text)"
              ></BaseButton>
            </template>
          </div>
          <template v-if="nav[0].active">
            <div class="search__result">
              <router-link
                v-for="(item, key) in searchResult.artworks.list"
                :key="`artwork-${key}`"
                :to="{ path: `/artworks/${item.slug}` }"
                v-slot="{ navigate }"
                custom
              >
                <div class="artwork" @click="navigate">
                  <div class="image-box">
                    <img class="image" :src="item.search_image" />
                    <div class="code text-sub-title" v-if="item.sku">{{ item.sku }}</div>
                    <div class="hover-container text-sub-title">Click to View</div>
                  </div>
                  <h3 class="title text-link">{{ item.title }}</h3>
                  <!-- <span class="price text-base">${{ item.price }}</span> -->
                  <span class="author text-base">{{ item.author }}</span>
                </div>
              </router-link>
            </div>
            <InfiniteLoading
              v-if="searchResult.artworks.has_more"
              class="infinite-loading"
              @infinite="loadMore"
            ></InfiniteLoading>
          </template>
          <template v-else-if="nav[1].active">
            <div class="search__result">
              <router-link
                v-for="(item, key) in searchResult.artists.list"
                :key="`artist-${key}`"
                :to="{ path: `/artists/${item.slug}` }"
                v-slot="{ navigate }"
                custom
              >
                <div class="artist" @click="navigate">
                  <img class="image" :src="item.search_image" />
                  <h3 class="name text-link">{{ item.full_name }}</h3>
                  <span class="country text-base">{{ item.region }}</span>
                </div>
              </router-link>
            </div>
            <InfiniteLoading
              v-if="searchResult.artists.has_more"
              class="infinite-loading"
              @infinite="loadMore"
            ></InfiniteLoading>
          </template>
          <template v-else-if="nav[2].active">
            <div class="search__result">
              <router-link
                v-for="(item, key) in searchResult.articles.list"
                :key="`article-${key}`"
                :to="{ path: `${item.slug}` }"
                v-slot="{ navigate }"
                custom
              >
                <div class="article" @click="navigate">
                  <div class="image-box">
                    <img class="image" :src="item.search_image" />
                    <div class="hover-container text-sub-title">Click to View</div>
                  </div>
                  <span class="tag text-link">{{ item.type }}</span>
                  <h3
                    class="title text-link"
                    @click="$router.push({ path: `/media/${item.slug}` })"
                  >
                    {{ item.title }}
                  </h3>
                  <span class="data text-sub-title">{{ item.date_published }}</span>
                </div>
              </router-link>
            </div>
            <InfiniteLoading
              v-if="searchResult.articles.has_more"
              class="infinite-loading"
              @infinite="loadMore"
            ></InfiniteLoading>
          </template>
          <template v-else-if="nav[3].active">
            <div class="search__result">
              <router-link
                v-for="(item, key) in searchResult.events.list"
                :key="`events-${key}`"
                :to="{ path: `${item.slug}` }"
                v-slot="{ navigate }"
                custom
              >
                <div class="events" @click="navigate">
                  <div class="image-box">
                    <img class="image" :src="item.search_image" />
                    <div class="hover-container text-sub-title">Click to View</div>
                  </div>
                  <div class="type text-link">{{ item.type }}</div>
                  <h3 class="title text-link">{{ item.title }}</h3>
                  <span class="data text-sub-title">{{ item.date_published }}</span>
                </div>
              </router-link>
            </div>
            <InfiniteLoading
              v-if="searchResult.events.has_more"
              class="infinite-loading"
              @infinite="loadMore"
            ></InfiniteLoading>
          </template>
        </template>
      </div>
      <Footer :footer="true"></Footer>
    </div>
  </transition>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapGetters, mapMutations, mapActions } from "vuex";

import BaseInput from "@/components/inputs/BaseInput.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconMenu from "@/components/icons/IconMenu.vue";
import IconClose from "@/components/icons/IconClose.vue";
import Logo from "@/components/icons/Logo.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    BaseInput,
    IconButton,
    BaseButton,
    IconMenu,
    IconClose,
    Logo,
    Footer,
    InfiniteLoading,
  },
  props: {
    searchActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      searched: false,
      nav: [
        {
          active: true,
          text: "ART",
          key: "artworks",
          count: 0,
        },
        {
          active: false,
          text: "ARTISTS",
          key: "artists",
          count: 0,
        },
        {
          active: false,
          text: "ARTICLES",
          key: "articles",
          count: 0,
        },
        {
          active: false,
          text: "EVENTS",
          key: "events",
          count: 0,
        },
      ],
      busy: false,
      page: 1,
    };
  },
  computed: {
    ...mapGetters("publicapi/search", {
      searchResult: "getSearchResult",
    }),
  },
  async created() {
    const { search } = this.$route.query;
    if (search) {
      this.search = search ?? "";
      await this.apiSearch(this.search);
    }
  },
  watch: {
    searchActive() {
      //this.search = "";
      // this.nav.map(item => {
      //   item.active = false;
      // });
      // this.nav[0].active = true;
      // this.setSearchResult({
      //   data: {
      //     articles: { count: 0, list: [], has_more: false },
      //     artists: { count: 0, list: [], has_more: false },
      //     artworks: { count: 0, list: [], has_more: false },
      //     events: { count: 0, list: [], has_more: false },
      //   },
      //   page: 1,
      // });
    },
    "$route.query.search": function () {
      this.search = this.$route.query.search ?? "";
    },
  },
  methods: {
    ...mapActions("publicapi/search", ["apiSearch"]),
    ...mapMutations("publicapi/search", ["setSearchResult"]),

    changeInput(event) {
      this.search = event;
      this.searched = false;
    },
    inputSearch(val) {
      this.loading = true;
      this.searched = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        await this.apiSearch({ search: this.search, page: 1 });
        this.nav.map(item => {
          item.count = this.searchResult[item.key].count;
          item.active = false;
        });
        for (const item of this.nav) {
          if (item.count > 0) {
            item.active = true;
            break;
          }
        }
        this.loading = false;
      }, 200);
    },
    clickNav(textButton) {
      this.nav.map(item => {
        if (item.text === textButton) item.active = true;
        else item.active = false;
      });
    },
    async loadMore($state) {
      this.busy = true;
      this.page += 1;
      await this.apiSearch({ search: this.search, page: this.page });
      $state?.loaded();
      this.busy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  z-index: 1000;
  background-color: $lightBlack;
  &__fade {
    &-enter-active,
    &-leave-active {
      transition: all 1s ease;
    }
    &-enter {
      transform: translateX(100%);
      &-to {
        transform: translateX(0%);
      }
    }
    &-leave {
      transform: translateX(0%);
      &-to {
        transform: translateX(100%);
      }
    }
  }
  &__title {
    text-align: center;
  }
  &__input {
    width: 100%;
    max-width: none;
    margin: 0;
    flex-grow: 1;
    &::v-deep {
      .form-control__input {
        height: 4.4rem;
        padding: 0;
        font-family: $fontFrutigerNeue;
        font-weight: 500;
        font-size: 4rem;
        line-height: 1.25;
        letter-spacing: -1.33px;
        color: $white;
        border: none;
        @media screen and (max-width: $xs) {
          font-size: 3rem;
        }
      }
      .form-control__label {
        font-family: $fontFrutigerNeue;
        font-size: 4rem;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: -1.33px;
        color: $white;
        opacity: 0.7;
        background: none;
        padding: 0;
        left: 0;
        @media screen and (max-width: $xs) {
          font-size: 3rem;
        }
      }
      .form-control__input:focus ~ .form-control__label,
      .form-control__input:not(:placeholder-shown) ~ .form-control__label {
        display: none;
      }
    }
    &-container {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 2rem 0 2rem;
      border-bottom: 1px solid $white;
      width: 100%;
      max-width: calc(100% - 21rem);
      @media screen and (max-width: $xs) {
        max-width: 100%;
      }
    }
  }
  &__button {
    @media screen and (max-width: $xs) {
      min-width: 10rem;
      height: 4.5rem;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 23rem 10.5rem 3rem;
    flex-grow: 1;
    transition: all 0.5s ease;
    @media (max-width: $xxs) {
      padding: 16rem 25px 3rem;
    }
    &--search-active {
      padding-top: 6rem;
      @media (max-width: $xxs) {
        padding-top: 2.35rem;
      }
    }
  }
  &__icon {
    width: auto;
    height: auto;
  }
  &__mesage {
    padding: 15px 10.5rem 0;
    width: 100%;
    @media screen and (max-width: $xs) {
      padding: 15px 0 0;
    }
  }
  &__result {
    padding-top: 5.5rem;
    padding-bottom: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 7rem;
    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $xs) {
      padding-top: 6rem;
      grid-template-columns: 1fr;
      grid-row-gap: 7rem;
    }
    &-nav {
      padding-top: 10rem;
      display: flex;
      @media screen and (max-width: $xs) {
        padding-top: 7rem;
        flex-wrap: wrap;
      }
      &-button {
        border-radius: 19px;
        height: 4rem;
        padding: 0 20px;
        margin-right: 10px;
        @media screen and (max-width: $xs) {
          margin-bottom: 10px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    & > .artwork {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }

      .image-box {
        position: relative;

        .image {
          width: 100%;
          height: 30vw;
          @media screen and (max-width: $md) {
            height: 40vw;
          }
          @media screen and (max-width: $xs) {
            height: 39rem;
          }
        }

        .code {
          position: absolute;
          right: 9px;
          bottom: 9px;
          display: flex;
          justify-content: center;
          align-content: center;
          text-transform: uppercase;
          border: 1px solid $white;
          border-radius: 5px;
          //width: max-content;
          background-color: rgba(0, 0, 0, 0.45);
          min-width: 7.2rem;
          font-size: 1.4rem;
          line-height: 2.5rem;
          padding: 0.1rem 1rem;
          z-index: 2;
          max-width: 30%;
          display: -webkit-box;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
        }
      }

      .title {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1rem;
      }

      // .price {
      //   font-size: 1.8rem;
      //   line-height: 2.8rem;
      // }

      .author {
        margin-top: 0.5rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
        text-transform: uppercase;
        word-spacing: 3px;
        @media screen and (max-width: $xs) {
          margin-top: 1rem;
          font-size: 1.6rem;
          line-height: 2.8rem;
        }
      }
    }
    & > .artist {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .image {
        width: 100%;
        height: 30vw;
        @media screen and (max-width: $md) {
          height: 40vw;
        }
        @media screen and (max-width: $xs) {
          height: 39rem;
        }
      }

      .name {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1.2rem;
      }

      .country {
        margin-top: 0.8rem;
        @media screen and (max-width: $xs) {
          font-size: 1.4rem;
          line-height: 2.5rem;
        }
      }
    }
    & > .article {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }
      .image-box {
        position: relative;

        .image {
          width: 100%;
          height: 30vw;
          @media screen and (max-width: $md) {
            height: 40vw;
          }
          @media screen and (max-width: $xs) {
            height: 39rem;
          }
        }

        .code {
          position: absolute;
          right: 9px;
          bottom: 9px;
          display: flex;
          justify-content: center;
          align-content: center;
          text-transform: uppercase;
          border: 1px solid $white;
          border-radius: 5px;
          //width: max-content;
          background-color: rgba(0, 0, 0, 0.45);
          min-width: 7.2rem;
          font-size: 1.4rem;
          line-height: 2.5rem;
          padding: 0.1rem 1rem;
          z-index: 2;
          max-width: 30%;
          display: -webkit-box;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
        }
      }
      .tag {
        display: flex;
        justify-content: center;
        align-content: center;
        width: max-content;
        text-transform: uppercase;
        border: 1px solid $white;
        border-radius: 5px;
        font-size: 14px;
        line-height: 1.8;
        padding: 0.1rem 1rem;
        margin: 10px 0;
      }
      .title {
        font-size: 3.125rem;
        line-height: 1.3;
        margin-bottom: 12px;
        @media screen and (max-width: $xxl) {
          font-size: 25px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .data {
        font-size: 14px;
        line-height: 1.8;
        text-transform: uppercase;
      }
    }
    & > .events {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }
      .image-box {
        position: relative;
        .image {
          height: 27vw;
          width: 100%;
          object-fit: cover;
          background-size: cover;
          background-position: center;
          border-radius: 4px;
          @media screen and (max-width: $md) {
            height: 40vw;
          }
          @media screen and (max-width: $xs) {
            height: 90vw;
          }
        }
        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
          border-radius: 4px;
        }
      }
      .type {
        display: flex;
        justify-content: center;
        align-content: center;
        text-transform: uppercase;
        border: 1px solid $white;
        border-radius: 4px;
        width: max-content;
        min-width: 9.7rem;
        margin-top: 10px;
        font-size: 1.4rem;
        line-height: 2.5rem;
        padding: 0.1rem 1rem;
      }
      .title {
        font-size: 3.125rem;
        line-height: 1.3;
        margin-top: 10px;
      }
      .data {
        margin-top: 12px;
        font-size: 1.4rem;
        line-height: 2.5rem;
        text-transform: uppercase;
        @media screen and (max-width: $xs) {
          margin-top: 1rem;
        }
      }
    }
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 40px 10.5rem 0;
  transition: all 0.3s ease;
  @media (max-width: $xxs) {
    padding: 40px 25px 0;
  }
}
.menu {
  display: flex;
  &__button {
    border: none;
    border-radius: 50%;
    background-color: transparent;
    height: 6.4rem;
    width: 6.4rem;
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.2);
    }
    @media (max-width: $xxs) {
    }
  }
  .button:first-of-type {
    margin-right: 4rem;
    @media (max-width: $xxs) {
      margin-right: 1.5rem;
    }
  }
}

.logo {
  height: 6.4rem;
  width: max-content;
}
</style>

