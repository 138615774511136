import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import user from "./modules/private/user";
import general from "./modules/private/general";
import publicapi from "./modules/public/index";

import artists from "./modules/private/artists";
import publications from "./modules/private/publications";
import artworks from "./modules/private/artworks";

import exhibitions from "./modules/private/exhibitions";
import events from "./modules/private/events";
import privateViewingRoom from "./modules/private/privateViewingRoom";

import news from "./modules/private/news";
import pressreleases from "./modules/private/pressreleases";
import looseThreads from "./modules/private/looseThreads";
import memoriams from "./modules/private/memoriams";

import arttextstyle from "./modules/private/arttextstyle";

import filters from "./modules/private/filters";
import tags from "./modules/private/tags";
import comments from "./modules/private/comments";
import artAdvisory from "./modules/private/artAdvisory";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    adminLoader: true,
    breadcrumbs: [],
    menu: false,
    isFilter: false,
  },
  getters: {
    getAdminLoader(state) {
      return state.adminLoader;
    },
    getBreadcrumbs(state) {
      return state.breadcrumbs;
    },
    getMenuValue(state) {
      return state.menu;
    },
    getIsFilter(state) {
      return state.isFilter;
    },
  },
  mutations: {
    setAdminLoader(state, payload) {
      state.adminLoader = payload;
    },
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
    setOpenMenu(state, payload) {
      state.menu = payload;
    },
    setIsFilter(state, payload) {
      state.isFilter = payload;
    },
  },
  actions: {},
  modules: {
    auth,
    user,
    general,
    artists,
    artworks,
    publications,
    filters,
    exhibitions,
    events,
    privateViewingRoom,
    looseThreads,
    memoriams,
    pressreleases,
    news,
    arttextstyle,
    comments,
    artAdvisory,
    tags,
    publicapi,
  },
});

