import Vue from "vue";
export default {
  namespaced: true,
  state: {
    initialfilters: [],
    filters: [],
    search: "",
    selectedFilters: [],
    selectedFiltersNumbers: 0,
  },
  getters: {
    getFilters(state) {
      return state.filters;
    },
    getInitialfilters(state) {
      return state.initialfilters;
    },
    getSearch(state) {
      return state.search;
    },
    getSelectedFilters(state) {
      return state.selectedFilters;
    },
    getSelectedFiltersNumbers(state) {
      return state.selectedFiltersNumbers;
    },
  },
  mutations: {
    createFilters(state, payload) {
      const { response, search } = payload;

      const initialfilters = response.map(el => {
        const selectedFiltersWithKey = state.selectedFilters.filter(
          selected => selected.key === el.key
        );
        const items = el.items.map((item, idx) => {
          const selectedItems =
            selectedFiltersWithKey[0] && selectedFiltersWithKey[0].items.length > 0
              ? selectedFiltersWithKey[0].items
              : false;
          return {
            ...item,
            id: item.id ? item.id : item.period ? item.period : idx,
            selected: selectedItems
              ? selectedItems.filter(select => item.title === select.title).length
              : false,
            val: null,
          };
        });
        return {
          ...el,
          type: el.key === "materials" ? "search" : el.key === "price" ? "range" : "tag",
          open: false,
          items:
            search && el.key === "availabilities"
              ? items.filter(el => el.title.toLowerCase().includes(search.toLowerCase()))
              : items,
        };
      });
      state.filters = JSON.parse(JSON.stringify(initialfilters));
      state.initialfilters = JSON.parse(JSON.stringify(initialfilters));
    },
    createSelectedFilters(state, payload) {
      const { response } = payload;
      let selectedFilters = [];
      response.forEach(item => {
        selectedFilters.push({ key: item.key, items: [] });
      });
      state.selectedFilters = selectedFilters;
    },
    updateFilters(state, payload) {
      let { key, filter, val = "", searched = [], valid } = payload;
      for (let el of state.filters) {
        if (el.key === key && key === "materials") {
          if (searched.length) {
            el.items = searched.map(el => {
              return {
                ...el,
                selected: true,
              };
            });
          } else if (!val) {
            el.items.forEach(element => {
              if (element.id === filter.id) {
                element.selected = !element.selected;
              }
            });
          }

          break;
        } else if (
          el.key === "price" &&
          (key === "price_start" || key === "price_end" || key === "price")
        ) {
          el.items.forEach(element => {
            if (element.id === filter.id) {
              element.selected = !val || !valid ? false : true;
              element.val = val ? val : "";
            }
          });
          break;
        } else if (el.key === key) {
          el.items.forEach(element => {
            if (element.id === filter.id) {
              element.selected = !element.selected;
            }
          });
          break;
        }
      }
    },
    updateSelectedFilters(state, payload) {
      let selectedFilters = state.selectedFilters;
      let idx = 0;
      for (let el of state.filters) {
        let items = state.search && selectedFilters.length ? selectedFilters[idx].items : [];
        el.items.forEach(element => {
          const hasEl = items.filter(i => i.id === element.id).length > 0;
          if (element.selected && !hasEl) {
            items.push(element);
          }
        });
        if (selectedFilters.length) {
          selectedFilters[idx].items = items;
          idx++;
        }
      }

      let number = 0;
      selectedFilters.forEach(el => {
        const selected = el.items.filter(item => item.selected || item.val);
        number += selected.length;
      });
      state.selectedFiltersNumbers = number;
      state.selectedFilters = selectedFilters;
    },
    removeFromSelectedFilters(state, payload) {
      let selectedFilters = state.selectedFilters;
      selectedFilters = selectedFilters.map(element => {
        if (element.key === payload.key) {
          let items = [];
          items = element.items.filter(el => {
            return el.id !== payload.filter.id;
          });
          return { ...element, items };
        } else return element;
      });

      let number = 0;
      selectedFilters.forEach(el => {
        const selected = el.items.filter(item => item.selected || item.val);
        number += selected.length;
      });
      state.selectedFiltersNumbers = number;
      state.selectedFilters = selectedFilters;
    },
    changeSearch(state, payload) {
      state.search = payload;
    },
    clear(state) {
      state.search = "";
      state.selectedFilters = [];
    },
  },
  actions: {
    async apiGetFiltersBlock({ commit }, { list, type, search, isFirst }) {
      await Vue.axios
        .get(`/api/public/filters?list=${list}&type=${type}&search=${search}`)
        .then(res => res.data)
        .then(data => {
          commit("changeSearch", search);
          commit("createFilters", { response: data.response, search });
          isFirst && commit("createSelectedFilters", { response: data.response, search });
        });
    },
    clear({ commit }, initialfilters) {
      const filters = JSON.parse(JSON.stringify(initialfilters));
      commit("clear");
      commit("createFilters", { response: filters, search: "" });
      commit("createSelectedFilters", { response: filters, search: "" });
    },
  },
};

