import Vue from "vue";
export default {
  namespaced: true,
  state: {
    regions: [],
    exhibitionTypes: [],
    looseThreads: [
      {
        id: 1,
        title: "On Art",
      },
      {
        id: 2,
        title: "On Artists",
      },
      {
        id: 3,
        title: "Resources",
      },
    ],
    colors: [],
    searchedColorsItems: [],
    colorsLoading: false,
    artTypes: [],
    searchedMaterialsItems: [],
    materialsLoading: false,
    saleStatus: [
      {
        id: 0,
        title: "Donated",
      },
      {
        id: 1,
        title: "For Sale",
      },
      {
        id: 2,
        title: "For Sale (Not in Inventory)",
      },
      {
        id: 3,
        title: "Gifted",
      },
      {
        id: 4,
        title: "On Loan",
      },
      {
        id: 5,
        title: "Returned to Artist",
      },
      {
        id: 6,
        title: "Sold",
      },
      {
        id: 7,
        title: "Unavailable",
      },
      {
        id: 8,
        title: "On Hold",
      },
    ],
    conditionStatus: [
      {
        id: 0,
        title: "Poor",
      },
      {
        id: 1,
        title: "Fair",
      },
      {
        id: 2,
        title: "Good",
      },
      {
        id: 3,
        title: "Excellent",
      },
    ],
    searchedRegionsItems: [],
    regionsLoading: false,
  },
  getters: {
    getRegions(state) {
      return state.regions;
    },
    getExhibitionTypes(state) {
      return state.exhibitionTypes;
    },
    getLooseThreads(state) {
      return state.looseThreads;
    },
    getColors(state) {
      return state.colors;
    },
    getArtTypes(state) {
      return state.artTypes;
    },
    getSearchedMaterials(state) {
      return state.searchedMaterialsItems;
    },
    getSearchedColors(state){
      return state.searchedColorsItems;
    },
    getChangesMaterialsLoading(state) {
      return state.materialsLoading;
    },
    getChangesColorsLoading(state){
      return state.colorsLoading;
    },
    getSearchedRegions(state) {
      return state.searchedRegionsItems;
    },
    getChangesRegionsLoading(state) {
      return state.regionsLoading;
    },
    getSaleStatus(state) {
      return state.saleStatus;
    },
    getConditionStatus(state) {
      return state.conditionStatus;
    },
  },
  mutations: {
    setRegions(state, payload) {
      state.regions = payload;
    },
    setExhibitionTypes(state, payload) {
      state.exhibitionTypes = payload;
    },
    setColors(state, payload) {
      state.colors = payload;
    },
    setArtTypes(state, payload) {
      state.artTypes = payload;
    },
    setSearchedMaterialsItems(state, payload) {
      state.searchedMaterialsItems = payload;
    },
    setSearchedColorsItems(state, payload){
      state.searchedColorsItems = payload
    },
    setChangesMaterialsLoading(state, payload) {
      state.materialsLoading = payload;
    },
    setChangesColorsLoading(state, payload){
      state.colorsLoading = payload
    },
    setSearchedRegionsItems(state, payload) {
      state.searchedRegionsItems = payload;
    },
    setChangesRegionsLoading(state, payload) {
      state.regionsLoading = payload;
    },
  },
  actions: {
    async apiPostUploadImage({ commit }, payload) {
      const fd = new FormData();
      fd.append("files[]", payload);

      let image = false;
      await Vue.axios
        .post("/api/admin/file-uploader", fd)
        .then(res => res.data)
        .then(data => {
          image = data.response.valid[0];
        });
      return image;
    },
    async apiDeleteUploadImage({ commit }, payload) {
      await Vue.axios
        .delete(`/api/admin/file-uploader`, {
          params: {
            files: payload,
          },
        })
        .then(res => res.data)
        .then(data => {});
    },

    async apiGetRegions({ commit }) {
      await Vue.axios
        .get(`/api/admin/regions`)
        .then(res => res.data)
        .then(data => {
          commit("setRegions", data.response);
        });
    },

    async apiGetExhibitionTypes({ commit }, { limit = "" }) {
      let query = {
          limit,
        },
        queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/exhibition-types?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          commit("setExhibitionTypes", data.response);
        });
    },

    async apiGetColors({ commit }) {
      await Vue.axios
        .get(`/api/admin/colors`)
        .then(res => res.data)
        .then(data => {
          commit("setColors", data.response);
        });
    },
    async apiGetArtTypes({ commit }) {
      await Vue.axios
        .get(`/api/admin/art-types`)
        .then(res => res.data)
        .then(data => {
          commit("setArtTypes", data.response);
        });
    },

    async apiGetSearchedMaterials({ commit }, { search = "", excludeIdx }) {
      let success = false,
        limit = "all";
      let excludeIdxString = "";
      if (excludeIdx.length > 0) {
        excludeIdxString = excludeIdx
          .map(el => {
            return el.id;
          })
          .join(",");
      }
      commit("setChangesMaterialsLoading", true);

      await Vue.axios
        .get(`/api/admin/materials?limit=${limit}&search=${search}&exclude_ids=${excludeIdxString}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setSearchedMaterialsItems", data.response);
        })
        .finally(() => {
          commit("setChangesMaterialsLoading", false);
        });
      return success;
    },
    async apiGetSearchedColors({ commit }, { search = "", excludeIdx }) {
      let success = false,
        limit = "all";
      let excludeIdxString = "";
      if (excludeIdx.length > 0) {
        excludeIdxString = excludeIdx
          .map(el => {
            return el.id;
          })
          .join(",");
      }
      commit("setChangesColorsLoading", true);

      await Vue.axios
        .get(`/api/admin/colors?limit=${limit}&search=${search}&exclude_ids=${excludeIdxString}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setSearchedColorsItems", data.response);
        })
        .finally(() => {
          commit("setChangesColorsLoading", false);
        });
      return success;
    },
    async apiPostMaterial({ commit }, payload) {
      commit("setChangesMaterialsLoading", true);
      let result = {
        success: false,
        item: null,
        error: null,
      };
      await Vue.axios
        .post("/api/admin/material", payload)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.item = data.response;
        })
        .catch(e => {
          if (e.response.status === 422) {
            if (e.response.data?.detail?.title[0]) {
              result.error = "The tag has already been taken";
              commit("setError", "The tag has already been taken");
            }
          }
        })
        .finally(() => {
          commit("setChangesMaterialsLoading", false);
        });
      return result;
    },
    async apiPostColor({ commit }, payload) {
      commit("setChangesColorsLoading", true);
      let result = {
        success: false,
        item: null,
        error: null,
      };
      await Vue.axios
        .post("/api/admin/color", payload)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.item = data.response;
        })
        .catch(e => {
          if (e.response.status === 422) {
            if (e.response.data?.detail?.title[0]) {
              result.error = "The color has already been taken";
              commit("setError", "The color has already been taken");
            }
          }
        })
        .finally(() => {
          commit("setChangesColorsLoading", false);
        });
      return result;
    },

    async apiGetSearchedRegions({ commit }, { search = "", excludeIdx }) {
      let success = false,
        limit = "all";
      let excludeIdxString = "";
      if (excludeIdx.length > 0) {
        excludeIdxString = excludeIdx
          .map(el => {
            return el.id;
          })
          .join(",");
      }
      commit("setChangesRegionsLoading", true);

      await Vue.axios
        .get(`/api/admin/regions?limit=${limit}&search=${search}&exclude_ids=${excludeIdxString}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setSearchedRegionsItems", data.response);
        })
        .finally(() => {
          commit("setChangesRegionsLoading", false);
        });
      return success;
    },
    async apiPostRegion({ commit }, payload) {
      commit("setChangesRegionsLoading", true);
      let result = {
        success: false,
        item: null,
        error: null,
      };
      await Vue.axios
        .post("/api/admin/region", payload)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          result.item = data.response;
        })
        .catch(e => {
          if (e.response.status === 422) {
            if (e.response.data?.detail?.title[0]) {
              result.error = "The tag has already been taken";
              commit("setError", "The tag has already been taken");
            }
          }
        })
        .finally(() => {
          commit("setChangesRegionsLoading", false);
        });
      return result;
    },
  },
};

