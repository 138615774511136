import Vue from "vue";
export default {
  namespaced: true,
  state: {
    serverError: {
      title: null,
    },
    artists: [],
    artAdvisories: [],
    artAdvisory: {
      images: [],
    },
  },
  getters: {
    getServerError(state) {
      return state.serverError;
    },
    getArtists(state) {
      return state.artists;
    },
    getArtAdvisories(state) {
      return state.artAdvisories;
    },
    getArtAdvisory(state) {
      return state.artAdvisory;
    },
  },
  mutations: {
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          title: payload,
        };
    },
    setArtists(state, payload) {
      let data = [];
      payload.map((artist, index) => {
        data.push({
          id: index,
          title: artist.name,
        });
      });
      state.artists = data;
    },
    setArtAdvisories(state, payload) {
      state.artAdvisories = payload;
    },
    setArtAdvisory(state, payload) {
      state.artAdvisory = payload;
    },
  },
  actions: {
    async apiArtAdvisories({ commit }) {
      let success = false;
      await Vue.axios
        .get(`/api/public/art-advisories`)
        .then(res => res.data)
        .then(data => {
          commit("setArtAdvisories", data.response ?? []);
          success = true;
        });
      return success;
    },
    async apiArtAdvisory({ commit }, slug) {
      let success = false;
      await Vue.axios
        .get(`/api/public/art-advisory/${slug}`)
        .then(res => res.data)
        .then(data => {
          commit("setArtAdvisory", data.response);
          success = true;
        });
      return success;
    },
    async apiFormsPresentationAssistance({ commit }, payload) {
      let success = false;
      const data = new FormData();
      data.append("name", payload.name);
      data.append("email", payload.email);
      data.append("phone", payload.phone);
      data.append("business_name", payload.business_name);
      data.append("business_address", payload.business_address);
      data.append("description", payload.description);
      payload.images.map((image, index) => {
        data.append(`images[${index}]`, image);
      });
      await Vue.axios
        .post(`/api/public/forms/presentation-assistance`, data)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
    async apiFormsVirtualPlacementInquire({ commit }, payload) {
      let success = false;
      const data = new FormData();
      data.append("name", payload.name);
      data.append("email", payload.email);
      data.append("phone", payload.phone);
      data.append("service_location", payload.service_location);
      data.append("height", payload.height);
      data.append("width", payload.width);
      data.append("length", payload.length);
      data.append("description", payload.description);
      payload.images.map((image, index) => {
        data.append(`images[${index}]`, image);
      });
      await Vue.axios
        .post(`/api/public/forms/virtual-placement-inquire`, data)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
    async apiGetArtists({ commit }) {
      await Vue.axios.get(`/api/public/active-artists-names`).then(response => {
        console.log(response?.data.response);
        commit("setArtists", response?.data.response ?? []);
      });
    },
    async apiFormsCommissions({ commit }, payload) {
      let success = false;
      const data = new FormData();
      data.append("artist", payload.artist);
      data.append("name", payload.name);
      data.append("email", payload.email);
      data.append("phone", payload.phone);
      data.append("service_location", payload.service_location);
      data.append("height", payload.height);
      data.append("width", payload.width);
      data.append("length", payload.length);
      data.append("description", payload.description);
      payload.images.map((image, index) => {
        data.append(`images[${index}]`, image);
      });
      await Vue.axios
        .post(`/api/public/forms/commissions`, data)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
  },
};

