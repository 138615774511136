import Vue from "vue";
export default {
  namespaced: true,
  state: {
    lastPressrelease: {},
    hasPressreleases: false,
    pressreleasesList: [],
    hasMorePages: false,
    currentPressrelease: {},
    randomPressreleasesList: {},
    filters: [],
  },
  getters: {
    getLastPressrelease(state) {
      return state.lastPressrelease;
    },
    getHasPressreleases(state) {
      return state.hasPressreleases;
    },
    getPressreleasesList(state) {
      return state.pressreleasesList;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getCurrentPressrelease(state) {
      return state.currentPressrelease;
    },
    getRandomPressreleasesList(state) {
      return state.randomPressreleasesList;
    },
    getFilters(state) {
      return state.filters;
    },
  },
  mutations: {
    setLastPressrelease(state, payload) {
      state.lastPressrelease = payload;
    },
    setHasPressreleases(state, payload) {
      state.hasPressreleases = payload;
    },
    setPressreleasesList(state, { data, page }) {
      if (page > 1) {
        state.pressreleasesList = state.pressreleasesList.concat(data);
      } else {
        state.pressreleasesList = data;
      }
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setCurrentPressrelease(state, payload) {
      state.currentPressrelease = payload;
    },
    setRandomPressreleasesList(state, payload) {
      state.randomPressreleasesList = payload;
    },
    setFilters(state, payload) {
      let filters = [
        {
          key: "",
          title: "ALL PRESS RELEASES",
          selected: true,
        },
      ];
      payload.forEach(el => {
        filters.push({ ...el, selected: el.selected ? el.selected : false });
      });
      state.filters = filters;
    },
    changeFilterValue(state, payload) {
      let filters = state.filters;
      filters.forEach(el => {
        el.selected = el.key === payload.key ? true : false;
      });
      state.filters = filters;
    },
  },
  actions: {
    async apiGetLastPressrelease({ commit }) {
      await Vue.axios.get("/api/public/press-releases/latest").then(response => {
        commit("setLastPressrelease", response?.data?.response ?? {});
        commit("setHasPressreleases", response?.data?.has_press_releases);
      });
    },

    async apiGetPressreleasesList(
      { commit },
      { latest_id = "", sort = "", page = "1", tags = "", year = "" }
    ) {
      let query = {
        latest_id,
        sort,
        page,
        tags,
        year,
      };
      let queryStr = "";
      let success = false;
      for (let key in query) {
        if (query[key]) {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios.get(`/api/public/press-releases/list?${queryStr}`).then(response => {
        success = true;
        commit("setPressreleasesList", { data: response.data.response, page });
        commit("setHasMorePages", response?.data?.hasMorePages);
      });
      return success;
    },

    async apiGetFiltersBlock({ commit }, { list, type }) {
      await Vue.axios
        .get(`/api/public/filters?list=${list}&type=${type}&belonging=press_releases`)
        .then(res => res.data)
        .then(data => {
          commit("setFilters", data?.response[0]?.items);
        });
    },

    async apiGetSpecificPressrelease({ commit }, slug) {
      await Vue.axios.get(`/api/public/press-releases/${slug}`).then(response => {
        commit("setCurrentPressrelease", response?.data?.response?.current ?? {});
        commit("setRandomPressreleasesList", response?.data?.response?.random_list ?? {});
      });
    },
  },
};
