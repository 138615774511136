<template>
  <button class="button" @click.prevent="$emit('click')" :disabled="disabled">
    <slot></slot>
    <span v-if="text" class="button__text">{{ text }}</span>
    <slot name="right"></slot>
  </button>
</template>
<script>
export default {
  props: {
    text: { type: String, required: false },
    disabled: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.1px solid $white;
  border-radius: 4px;
  background-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba($color: $light2Grey, $alpha: 0.6);
  }
  span {
    transition: all 0.3s ease;
  }
  svg {
    width: auto;
    height: auto;
    &::v-deep g {
      fill: $black;
      transition: all 0.3s ease;
    }
    &::v-deep > path {
      stroke: $black;
      transition: all 0.3s ease;
    }
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.6;
  }

  &__text {
    font-weight: 600;
    margin-left: 10px;
    color: $black;
  }
  &--admin {
    border: 1px solid $lightGrey;
    border-radius: 7px;
    text-transform: uppercase;
  }
  &--public {
    height: 4.8rem;
    padding: 0 20px;
    font-family: $fontFrutiger;
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
    background: $lightBlack;
    border: 0.4px solid #a8a8a8;
    border-radius: 20px;
    svg {
      &::v-deep g {
        fill: $white;
      }
      &::v-deep > path {
        stroke: $white;
      }
    }
    &-selected,
    &:hover {
      background: $white;
      .button__text {
        color: $black;
      }
      svg {
        &::v-deep g {
          fill: $black;
        }
        &::v-deep > path {
          stroke: $black;
        }
      }
    }
    .button__text {
      color: $white;
    }
    &-right {
      .button__text {
        margin: 0 10px 0 0;
      }
    }
  }
  &--auto {
    width: auto;
    height: 5.5rem;
    padding: 0 2.2rem;
    &-xs {
      height: 30px;
      font-size: 12px;
      width: auto;
      padding: 0 10px;
    }
  }
  &--lg {
    height: 7rem;
    padding: 0 24px;
    & .button__text {
      font-family: $fontMontserrat;
      font-size: 2rem;
      line-height: 1.5;
      @media (max-width: $xxxs) {
        font-size: 1.6rem;
      }
    }
    @media (max-width: $xxs) {
      height: 5.5rem;
      padding: 0 12px;
    }
  }
  &--xlg {
    height: 5.8rem;
    width: 5.8rem;
    @media (max-width: $xxs) {
      height: 4rem;
    width: 4rem;
    }
  }
  &--md {
    height: 5.2rem;
    width: 5.2rem;
  }
  &--sm {
    height: 4.4rem;
    width: 4.4rem;
  }
  &--xsm {
    height: 3.4rem;
    width: 3.4rem;
  }
  &--full-width {
    width: 100%;
  }
  &--brd-left-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &--brd-right-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--shadow {
    border: none;
    box-shadow: 0 2px 9px rgba($color: #000000, $alpha: 0.25);
  }
  &--ml-15 {
    margin-left: 15px;
  }
  &--white {
    background-color: $white;
    & .button__text {
      width: 100%;
      color: $black;
    }
    &:hover {
      background-color: $light2Grey;
    }
  }
  &--icon-white {
    svg {
      &::v-deep g {
        fill: $white;
      }
      &::v-deep > path {
        stroke: $white;
      }
    }
  }
  &--control {
    height: 6.8rem;
    width: 6.8rem;
    border-radius: 50%;
    border: 1px solid $white;
    background-color: $black;
    opacity: 0.8;
    z-index: 2;

    @media only screen and (max-width: $xxs) {
      height: 4.7rem;
      width: 4.7rem;
    }

    &:hover {
      background-color: $lightBlack;
    }
    svg {
      height: 1.3rem;

      @media only screen and (max-width: $xxs) {
        height: 0.9rem;
      }

      &::v-deep g {
        fill: $white;
      }
    }
  }
  &--outline-transparent {
    border-color: transparent;
    padding: 0 5px;
  }
  &--outline-white {
    background-color: transparent;
    border-color: $white;
    svg::v-deep g {
      fill: $white;
    }
    & .button__text {
      width: 100%;
      color: $white;
    }
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.1);
      & .button__text {
        color: $white;
      }
    }
  }
  &--outline-public-white {
    svg {
      &::v-deep g {
        fill: $white;
      }
      &::v-deep > path {
        stroke: $white;
      }
    }
  }
  &--outline-grey {
    border-color: $grey;
    &::v-deep g {
      fill: $grey;
    }
    &:hover,
    &.button--selected {
      background-color: $grey;
      span {
        color: $white;
      }
      svg::v-deep g {
        fill: $white;
      }
      svg::v-deep > path {
        stroke: $white;
      }
    }
  }
  &--ellipse {
    border-radius: 34px;
  }
  &--circle {
    border-radius: 50%;
  }
  &--right-icon {
    .button__text {
      margin-left: 0;
      margin-right: 5px;
    }
  }
}
</style>

