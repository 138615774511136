export default {
  namespaced: true,
  state: {
    data: {
      title: "",
      description: "",
      image: "",
    },
  },
  getters: {
    getShareData(state) {
      return state.data;
    },
  },
  mutations: {
    setShareData(state, data) {
      state.data = data;
    },
  },
  actions: {},
};
