<template>
  <transition name="email-subscription__fade">
    <div class="email-subscription__background" v-if="subscriptionActive">
      <ValidationObserver class="email-subscription" v-slot="{ invalid }" tag="div" ref="observer">
        <img
          class="email-subscription__image"
          src="@/assets/images/subscription__image.png"
          alt="art"
        />
        <div class="email-subscription__content">
          <IconButton
            class="email-subscription__close button--md button--outline-white"
            @click="$emit('hidenSubscription')"
          >
            <IconClose width="18" height="18"></IconClose>
          </IconButton>
          <p class="email-subscription__text text-link">
            Sign up for updates on exhibitions, artists and events.
          </p>
          <ValidationProvider
            tag="div"
            class="email-subscription__input"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <SubscriptionInput
              class=""
              label="Name"
              placeholder="Enter your name"
              v-model="form.name"
              :validateError="errors[0]"
            ></SubscriptionInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="email-subscription__input"
            :rules="{ required: true, email: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <SubscriptionInput
              class=""
              label="Email"
              placeholder="Enter your email"
              v-model="form.email"
              :validateError="errors[0]"
            ></SubscriptionInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="email-subscription__checkbox"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <BaseCheckbox
              class="checkbox--white"
              label="I agree to the <a href='/terms-and-conditions'>Terms & Conditions</a> and <a href='/privacy-policy'>Privacy Policy</a>."
              :checked="form.agree"
              @input="form.agree = $event"
              :validateError="errors[0]"
            ></BaseCheckbox>
          </ValidationProvider>
          <BaseButton
            class="email-subscription__button button--white"
            text="SUBMIT"
            :disabled="invalid || !form.agree"
            @click="submit"
          ></BaseButton>
        </div>
      </ValidationObserver>
    </div>
  </transition>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import SubscriptionInput from "@/components/inputs/SubscriptionInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconClose from "@/components/icons/IconClose.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    SubscriptionInput,
    BaseCheckbox,
    BaseButton,
    IconButton,
    IconClose,
  },
  props: {
    subscriptionActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        agree: false,
      },
    };
  },
  computed: {
    ...mapGetters("publicapi/contact", {
      serverError: "getServerError",
    }),
  },

  methods: {
    ...mapActions("publicapi/contact", ["apiSubscribe"]),

    async submit() {
      let success = await this.apiSubscribe({ email: this.form.email, name: this.form.name });
      if (success) {
        this.form.email = "";
        this.form.name = "";
        this.form.agree = false;
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.email-subscription {
  position: relative;
  display: flex;
  height: 51.2rem;
  width: 90rem;
  max-width: 90%;
  max-height: 90%;
  border: 1px solid $white;
  border-radius: 4px;
  &__background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    background: rgba($color: $lightBlack, $alpha: 0.78);
    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      backdrop-filter: blur(8px);
    }
    @supports not (backdrop-filter: none) {
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: $lightBlack, $alpha: 0.3);
        filter: blur(8px);
        z-index: -1;
      }
    }
  }
  &__fade {
    &-enter-active,
    &-leave-active {
      transition: all 0.5s ease;
    }
    &-enter {
      opacity: 0;
      &-to {
        opacity: 1;
      }
    }
    &-leave {
      opacity: 1;
      &-to {
        opacity: 0;
      }
    }
  }
  &__image {
    height: 100%;
    width: 26.4rem;
    @media screen and (max-width: $xs) {
      width: 0;
    }
  }
  &__close {
    position: absolute;
    top: 4.5rem;
    right: 3rem;
    @media screen and (max-width: $sm) {
      top: 2.5rem;
      right: 2rem;
    }
    @media screen and (max-width: $xs) {
      top: 2rem;
      right: 1.5rem;
      height: 4rem;
      width: 4rem;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 6rem 8rem 5.8rem 6rem;
    @media screen and (max-width: $sm) {
      padding: 4rem 6rem 4rem 4rem;
    }
    @media screen and (max-width: $xs) {
      padding: 4rem 5rem 3rem 2.5rem;
    }
  }
  &__text {
    margin-bottom: 3.2rem;
  }
  &__input {
    margin-bottom: 3rem;
  }
  &__checkbox {
    margin-bottom: 2rem;
  }
  &__button {
    min-height: 5.2rem;
  }
}
</style>
