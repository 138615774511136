import Vue from "vue";
export default {
    namespaced: true,
    state: {
        loading: false,
        artworks: [],
        hasMorePages: false,
        featuredExhibition: {
            slug: null,
        },
    },
    getters: {
        getArtworks(state) { return state.artworks; },
        getLoading(state) { return state.loading; },
        getHasMorePages(state) { return state.hasMorePages; },
        getFeaturedExhibition(state) {
            return state.featuredExhibition;
        },
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload;
        },
        setArtworks(state, { data, page }) {
            if (page > 1) {
                state.artworks = state.artworks.concat(data);
            } else {
                state.artworks = data;
            }
        },
        setHasMorePages(state, data) {
            state.hasMorePages = data;
        },
        setFeaturedExhibition(state, payload) {
            state.featuredExhibition = { ...payload, slug: payload?.slug ? payload.slug : null };
        },
    },
    actions: {
        async apiGetFeaturedExhibition({ commit }) {
            await Vue.axios
                .get(`/api/public/featured-home-page-exhibition`)
                .then(res => res.data)
                .then(data => {
                    commit("setFeaturedExhibition", data.response);
                });
        },
        async apiGetArtworks({ commit }, { page, exhibition_id = false }) {
            let string = exhibition_id ? `page=${page}&exhibition_id=${exhibition_id}` : `page=${page}`
            let success = false;
            commit("setLoading", true);
            commit("setHasMorePages", false);
            await Vue.axios
                .get(`/api/public/home-page/artworks?${string}`)
                .then(res => res.data)
                .then(data => {
                    success = true;
                    commit("setArtworks", { data: data.response.data, page });
                    commit("setHasMorePages", data.response.hasMorePages);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
            return success;
        },
    },
};
