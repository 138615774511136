import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    artworks: [],
    artworksHeaders: [
      {
        key: "title",
        title: "Title",
        sortable: true,
      },
      {
        key: "artist_name",
        title: "Artist",
        sortable: true,
        className: ["lg"],
      },
      {
        key: "status",
        title: "Status",
        sortable: true,
        className: ["md"],
        variables: "status",
      },
      {
        key: "availability",
        title: "Availability",
        sortable: true,
        className: ["md"],
        variables: "tag",
      },
      {
        key: "sku",
        title: "SKU",
        sortable: true,
        className: ["sm"],
      },
      {
        key: "price",
        title: "Price",
        sortable: true,
        className: ["sm"],
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: ["xsm", "center"],
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: ["xsm", "center"],
        variables: "icon",
        icon: "IconDelete",
      },
      {
        key: "view",
        title: "View",
        sortable: false,
        className: ["xsm", "center"],
        variables: "icon",
        icon: "IconEye",
      },
    ],
    art: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: {
      slug: null,
    },
    searchedItems: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getArtworksHeaders(state) {
      return state.artworksHeaders;
    },
    getArtworks(state) {
      return state.artworks;
    },
    getArt(state) {
      return state.art;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
    getSearchedArtworks(state) {
      return state.searchedItems;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setArtworks(state, payload) {
      let artworks = [];
      artworks = payload.map(el => {
        return {
          ...el,
          artist_name: el.artist_name ? el.artist_name : "—",
          preview_image: el.header_image ? el.header_image : false,
          subtitle: el.artist_name,
          availability: { main: el.availability, additional: [] },
          price: el.price,
          tag: el.availability,
          edit: true,
          view: true,
        };
      });
      if (state.page > 1) {
        state.artworks = state.artworks.concat(artworks);
      } else {
        state.artworks = artworks;
      }
    },
    initialArt(state) {
      state.art = null;
    },
    setArt(state, payload) {
      if (payload.attributes.length === 0) {
        payload.attributes = [
          {
            height: "",
            width: "",
            depth: "",
          },
        ];
      }
      state.art = {
        ...payload,
        artist: payload.artist != null ? payload.artist : { title: "", id: null },
        featured: payload.featured ? payload.featured : false,
        exhibitions: payload.exhibitions != null ? payload.exhibitions : [],
      };
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          slug: null,
        };
    },
    setSearchedItems(state, payload) {
      state.searchedItems = payload;
    },
  },
  actions: {
    async apiGetArtworks(
      { commit },
      {
        sort = "title",
        order = "asc",
        page = "1",
        art_types = "",
        materials = "",
        price_start = "",
        price_end = "",
        colors = "",
        regions = "",
        search = "",
        search_type = "",
        availabilities = "",
      }
    ) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      let query = {
        sort,
        order,
        page,
        art_types,
        materials,
        price_start,
        price_end,
        colors,
        regions,
        search,
        search_type,
        availabilities,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/artworks?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.hasMorePages);
          commit("setArtworks", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiPostArt({ commit }, payload) {
      let result = false;
      let data = {
        ...payload,
        artist_id: payload.artist.id,
        status: payload.status != null ? payload.status : "0",
      };
      commit("setChangesLoading", true);
      await Vue.axios
        .post("/api/admin/artwork", data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetArt({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/artwork/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setArt", data.response);
        });
      return result;
    },
    async apiPutArt({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      let data = {
        ...payload,
        artist_id: payload.artist.id,
      };
      await Vue.axios
        .put(`/api/admin/artwork/${payload.id}`, data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteArt({ commit }, id) {
      let result = false;
      await Vue.axios
        .delete(`/api/admin/artwork/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        });
      return result;
    },

    async apiGetSearchedArt({ commit }, { search = "" }) {
      let success = false;
      commit("setChangesLoading", true);
      await Vue.axios
        .get(`/api/admin/artworks/search?search=${search}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setSearchedItems", data.response);
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return success;
    },
  },
};

