<template>
  <svg :width="width" :height="height" viewBox="0 0 22 22">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        d="M0 9.70587921C0 4.35420227 4.35424805 0 9.70593262 0c5.3519287 0 9.70581054 4.35420227 9.70581054 9.70587921 0 5.35198212-4.35388183 9.70588684-9.70581054 9.70588684C4.35424805 19.41176605 0 15.05786133 0 9.70587921zm1.79187012.00004578c0 4.3637619 3.55029297 7.91402435 7.9140625 7.91402435s7.91394043-3.55030823 7.91394043-7.91407013c0-4.36375809-3.5501709-7.91402435-7.91394043-7.91402435-4.36376953 0-7.9140625 3.55030823-7.9140625 7.91407013z"
      />
      <path
        fill-rule="evenodd"
        d="M21.75484212 20.5717242l-4.79714673-4.79715417c-.32687718-.32687769-.85624856-.32687769-1.18312574 0-.32687718.32659628-.32687718.8565313 0 1.18312758l4.79714673 4.79715418C20.73513487 21.91827053 20.94908645 22 21.16327925 22c.21391138 0 .42810418-.08172947.59156287-.24514821.32687717-.32659628.32687717-.8565313 0-1.18312758z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: { type: String, default: "#fff" },
    height: { type: String, default: "22" },
    width: { type: String, default: "22" },
  },
};
</script>