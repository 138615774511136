import Vue from "vue";
export default {
  namespaced: true,
  state: {
    nextExhibition: {},
    serverError: {
      title: null,
    },
  },
  getters: {
    getNextExhibition(state) {
      return state.nextExhibition;
    },
    getServerError(state) {
      return state.serverError;
    },
  },
  mutations: {
    setNextExhibition(state, payload) {
      state.nextExhibition = payload;
    },

    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          title: payload,
        };
    },
  },
  actions: {
    async apiGetNextExhibition({ commit }) {
      await Vue.axios.get("/api/public/contact-us/next-exhibition").then(response => {
        commit("setNextExhibition", response?.data.response ?? {});
      });
    },

    async apiSendMessage({ commit }, payload) {
      let success = false;
      await Vue.axios
        .post(`/api/public/contact-us/send-message`, payload)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setError", null);
          Vue.toasted.show("Message successfully sent", {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },

    async apiSubscribe({ commit }, payload) {
      let success = false;
      await Vue.axios
        .post(`/api/public/subscribe`, payload)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setError", null);
          Vue.toasted.show("Thank you for subscribing to our mailing list!", {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
  },
};
