import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        loading: false,
        items: [],
        itemsHeaders: [
            {
                key: "title",
                title: "Blog Title",
                sortable: true,
                className: ['lg', 'bold'],
            },
            {
                key: "indent",
                className: ['indent', 'indent-xs'],
            },
            {
                key: "comment_info",
                title: "Comment Info",
                sortable: true,
                className: ['md'],
                variables: 'double',
            },
            {
                key: "preview",
                title: "Preview",
                sortable: true,
                className: ['xlg', 'bold'],
            },
            {
                key: "indent",
                className: ['indent', 'indent-xs'],
            },
            {
                key: "view",
                title: "View",
                sortable: false,
                className: ['xsm', 'center'],
                variables: 'icon',
                icon: 'IconEye'
            },
            {
                key: "delete",
                title: "Delete",
                sortable: false,
                className: ['xsm', 'center'],
                variables: 'icon',
                icon: 'IconDelete'
            },
        ],
        searchedItems: [],
        item: null,
        changesLoading: false,
        hasMorePages: false,
        page: 1,
    },
    getters: {
        getLoading(state) { return state.loading },
        getItemsHeaders(state) { return state.itemsHeaders },
        getItems(state) { return state.items },
        getItem(state) { return state.item },
        getChangesLoading(state) { return state.changesLoading },
        getHasMorePages(state) { return state.hasMorePages },
        getServerError(state) { return state.serverError }
    },
    mutations: {
        setLoading(state, payload) { state.loading = payload },
        setItems(state, payload) {
            if (state.page > 1) {
                state.items = state.items.concat(payload)
            } else { state.items = payload }
        },
        initialItem(state) { state.item = null },
        setItem(state, payload) { state.item = payload },
        setChangesLoading(state, payload) { state.changesLoading = payload },
        setPage(state, payload) { state.page = payload },
        setHasMorePages(state, payload) { state.hasMorePages = payload },
    },
    actions: {
        async apiGetComments({ commit }, { sort = 'title', order = 'asc', page = '1', search = '', limit = '' }) {
            let success = false;
            commit('setLoading', true)
            commit('setHasMorePages', false)
            let query = {
                sort,
                order,
                page,
                search,
                limit
            }
            let queryStr = '';
            for (let key in query) {
                if (query[key] != '') {
                    queryStr += `${key}=${query[key]}&`;
                }
            }
            await Vue.axios.get(`/api/admin/comments?${queryStr}`)
                .then((res) => res.data)
                .then((data) => {
                    success = true
                    commit('setPage', page)
                    commit("setHasMorePages", data.hasMorePages);
                    commit("setItems", data.response);
                })
                .finally(() => {
                    commit('setLoading', false)
                })
            return success
        },
        async apiDeleteComment({ commit }, id) {
            let result = false
            await Vue.axios.delete(`/api/admin/comment/${id}`)
                .then((res) => res.data)
                .then((data) => {
                    result = true
                    Vue.toasted.show(data.message, {
                        duration: 2000,
                        type: "success",
                    });
                })
            return result
        },
    }
}