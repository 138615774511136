import Vue from "vue";
import { articlesSlug, eventsSlug } from "../../../helper";
export default {
  namespaced: true,
  state: {
    serverError: {
      title: null,
    },
    search: "",
    searchSnippets: {
      articles: [],
      artists: [],
      artworks: [],
      events: [],
    },
    nav: [
      {
        active: true,
        text: "ART",
        key: "artworks",
        count: 0,
      },
      {
        active: false,
        text: "ARTISTS",
        key: "artists",
        count: 0,
      },
      {
        active: false,
        text: "ARTICLES",
        key: "articles",
        count: 0,
      },
      {
        active: false,
        text: "EVENTS",
        key: "events",
        count: 0,
      },
    ],
    searchResult: {
      articles: { count: 0, list: [], has_more: false },
      artists: { count: 0, list: [], has_more: false },
      artworks: { count: 0, list: [], has_more: false },
      events: { count: 0, list: [], has_more: false },
    },
    searchTagResult: [],
    hasMorePages: false,
    tagName: "",
  },
  getters: {
    getServerError(state) {
      return state.serverError;
    },
    getSearch(state) {
      return state.search;
    },
    getSearchSnippets(state) {
      return state.searchSnippets;
    },
    getNav(state) {
      return state.nav;
    },
    getSearchResult(state) {
      return state.searchResult;
    },
    getSearchTagResult(state) {
      return state.searchTagResult;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getTagName(state) {
      return state.tagName;
    },
  },
  mutations: {
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          title: payload,
        };
    },
    setNavActive(state, payload) {
      const nav = state.nav.map(item => {
        return (item = { ...item, active: item.text === payload });
      });
      state.nav = nav;
    },
    setNavCreating(state, payload) {
      const nav = state.nav.map(item => {
        return (item = { ...item, count: state.searchResult[item.key].count, active: false });
      });
      for (const item of nav) {
        if (payload && item.key === payload) {
          item.active = true;
          break;
        } else if (!payload && item.count > 0) {
          item.active = true;
          break;
        }
      }
      state.nav = nav;
    },
    setSearchResult(state, { data, page }) {
      if (
        data.articles.count === 0 &&
        data.artists.count === 0 &&
        data.artworks.count === 0 &&
        data.events.count === 0
      ) {
        state.searchResult = {
          result: false,
          ...data,
        };
      } else {
        const eventList =
          data.events.list.length > 0
            ? data.events.list.map(item => {
                return { ...item, slug: eventsSlug(item.type, item.slug) };
              })
            : [];

        const articlesList =
          data.articles.list.length > 0
            ? data.articles.list.map(item => {
                return { ...item, slug: articlesSlug(item.type, item.slug) };
              })
            : [];

        if (page === 1) {
          state.searchResult = {
            result: true,
            articles: {
              count: data.articles.count,
              has_more: data.articles.has_more,
              list: articlesList,
            },
            artists: { ...data.artists },
            artworks: { ...data.artworks },
            events: {
              count: data.events.count,
              has_more: data.events.has_more,
              list: eventList,
            },
          };
        } else {
          state.searchResult = {
            result: true,
            articles: {
              count: data.articles.count,
              has_more: data.articles.has_more,
              list: state.searchResult.articles.list.concat(articlesList),
            },
            artists: {
              ...data.artists,
              list: state.searchResult.artists.list.concat(data.artists.list),
            },
            artworks: {
              ...data.artworks,
              list: state.searchResult.artworks.list.concat(data.artworks.list),
            },
            events: {
              count: data.events.count,
              has_more: data.events.has_more,
              list: state.searchResult.events.list.concat(eventList),
            },
          };
        }
      }
    },
    setSearchSnippets(state, { data }) {
      state.searchSnippets = data;
    },
    setSearchField(state, val) {
      state.search = val;
    },
    setSearchTagResult(state, { data, page }) {
      if (page === 1) state.searchTagResult = data;
      else state.searchTagResult = state.searchTagResult.concat(data);
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setTagName(state, payload) {
      state.tagName = payload;
    },
  },
  actions: {
    async apiSearch({ commit }, { search, page = 1 }) {
      let success = false;
      await Vue.axios
        .get(`/api/public/search?search=${search}&page=${page}`)
        .then(res => res.data)
        .then(data => {
          commit("setSearchResult", { data: data.response, page });
          commit("setSearchField", search);

          success = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.search[0]);
          }
        });
      return success;
    },
    async apiSearchSnippets({ commit }, { search }) {
      let success = false;
      if (search) {
        await Vue.axios
          .get(`/api/public/search-snippets?search=${search}`)
          .then(res => res.data)
          .then(data => {
            commit("setSearchSnippets", { data: data.response });
          });
      } else {
        commit("setSearchSnippets", {
          data: { articles: [], artists: [], artworks: [], events: [] },
        });
      }
      return success;
    },
    async apiSearchTag({ commit }, { tag_id, page }) {
      let success = false;
      await Vue.axios
        .get(`/api/public/search-by-tag?tag_id=${tag_id}&page=${page}`)
        .then(res => res.data)
        .then(data => {
          commit("setSearchTagResult", { data: data.response, page });
          commit("setHasMorePages", data.hasMorePages);
          commit("setTagName", data.tagName);
          success = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
  },
};

