import Vue from "vue";
export default {
  namespaced: true,
  state: {
    serverError: {
      title: null,
    },
    memoriams: [],
    memoriam: {},
    activeLink: false
  },
  getters: {
    getServerError(state) {
      return state.serverError;
    },
    getMemoriams(state) {
      return state.memoriams;
    },
    getMemoriam(state) {
      return state.memoriam;
    },
    getActiveLink(state) {
      return state.activeLink;
    },
  },
  mutations: {
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          title: payload,
        };
    },
    setMemoriams(state, payload) {
      const memoriams = [];
      payload.map(item => {
        memoriams.push({
          ...item,
          active: false,
        });
      });
      state.memoriams = memoriams;
    },
    setMemoriam(state, payload) {
      state.memoriam = payload;
    },
    setMemoriamsActive(state, key) {
      state.memoriams.map(item => {
        if (item.key === key) item.active = true;
        else item.active = false;
      });
    },
    setActiveLink(state, payload) {
      state.activeLink = payload
    }
  },
  actions: {
    async apiMemoriams({ commit }) {
      let success = false;
      await Vue.axios
        .get(`/api/public/memoriams`)
        .then(res => res.data)
        .then(data => {
          commit("setMemoriams", data.response ?? []);
          success = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
    async apiMemoriam({ commit }, slug) {
      let success = false;
      await Vue.axios
        .get(`/api/public/memoriams/${slug}`)
        .then(res => res.data)
        .then(data => {
          commit("setMemoriam", data.response);
          success = true;
          commit("setError", null);
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.details.message);
          }
        });
      return success;
    },
  },
};
