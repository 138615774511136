import Vue from "vue";
import VueCookies from "vue-cookies";

const Login = () => import("@/views/auth/Login.vue");
const Invitation = () => import("@/views/auth/Invitation.vue");
const ChangePassword = () => import("@/views/auth/ChangePassword.vue");
const VerifyEmail = () => import("@/views/auth/VerifyEmail.vue");

Vue.use(VueCookies);

export default [
  {
    path: "/confirm-invite",
    name: "Invitation",
    component: Invitation,
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "empty",
    },
    beforeEnter: (to, from, next) => {
      const token = Vue.$cookies.get("BGAtoken") || null;
      if (token === null || token === "null") {
        next();
      } else next({ path: "/" });
    },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      layout: "empty",
    },
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: {
      layout: "empty",
    },
  },
];
