import Vue from "vue";
export default {
  namespaced: true,
  state: {
    lastNew: {},
    hasNews: false,
    newsList: [],
    hasMorePages: false,
    specificNew: {},
    filters: [],
  },
  getters: {
    getLastNew(state) {
      return state.lastNew;
    },
    getHasNews(state) {
      return state.hasNews;
    },
    getNewsList(state) {
      return state.newsList;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getSpecificNew(state) {
      return state.specificNew;
    },
    getFilters(state) {
      return state.filters;
    },
  },
  mutations: {
    setLastNew(state, payload) {
      state.lastNew = payload;
    },
    setHasNews(state, payload) {
      state.hasNews = payload;
    },
    setNewsList(state, { data, page }) {
      if (page > 1) {
        state.newsList = state.newsList.concat(data);
      } else {
        state.newsList = data;
      }
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setSpecificNew(state, payload) {
      state.specificNew = payload;
    },
    setFilters(state, payload) {
      let filters = [
        {
          key: "",
          title: "ALL NEWS",
          selected: true,
        },
      ];
      payload.forEach(el => {
        filters.push({ ...el, selected: el.selected ? el.selected : false });
      });
      state.filters = filters;
    },
    changeFilterValue(state, payload) {
      let filters = state.filters;
      filters.forEach(el => {
        el.selected = el.key === payload.key ? true : false;
      });
      state.filters = filters;
    },
  },
  actions: {
    async apiGetLastNew({ commit }) {
      await Vue.axios.get("/api/public/news/latest").then(response => {
        commit("setLastNew", response?.data?.response ?? {});
        commit("setHasNews", response?.data?.has_news);
      });
    },

    async apiGetNewsList(
      { commit },
      { latest_id = "", sort = "", page = "1", tags = "", year = "" }
    ) {
      let query = {
        latest_id,
        sort,
        page,
        tags,
        year,
      };

      let queryStr = "";
      let success = false;
      for (let key in query) {
        if (query[key]) {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios.get(`/api/public/news/list?${queryStr}`).then(response => {
        success = true;
        commit("setNewsList", { data: response.data.response, page });
        commit("setHasMorePages", response?.data?.hasMorePages);
      });
      return success;
    },

    async apiGetFiltersBlock({ commit }, { list, type }) {
      await Vue.axios
        .get(`/api/public/filters?list=${list}&type=${type}&belonging=news`)
        .then(res => res.data)
        .then(data => {
          commit("setFilters", data?.response[0]?.items);
        });
    },

    async apiGetSpecificNew({ commit }, slug) {
      await Vue.axios.get(`/api/public/news/${slug}`).then(response => {
        commit("setSpecificNew", response?.data?.response ?? {});
      });
    },
  },
};
