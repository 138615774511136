<template>
  <div class="cookies-popup" v-if="show">
    <p class="cookies-popup__text text-description">
      By continuing to browse this site, you accept the use of cookies for statistical purposes.
      <router-link class="cookies-popup__text text-description" to="/terms-and-conditions">
        Read more
      </router-link>
    </p>
    <div class="cookies-popup__box-buttons">
      <BaseButton
        class="cookies-popup__button button--white"
        text="ACCEPT"
        @click="accept"
      ></BaseButton>
      <BaseButton
        class="cookies-popup__button button--outline-white"
        text="MAYBE LATER"
        @click="later"
      ></BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    if (this.$cookies.isKey("acceptCookies")) this.show = false;
    else this.show = true;
  },
  methods: {
    accept() {
      this.$cookies.set("acceptCookies", true, "30d");
      this.show = false;
    },
    later() {
      this.$cookies.set("acceptCookies", false, "7d");
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cookies-popup {
  position: fixed;
  background: $dark2Grey;
  border: 1px solid rgba($color: $lightBlack, $alpha: 0.51);
  border-radius: 4px;
  padding: 3.5rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 105rem;
  width: 100%;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  z-index: 999;
  @media screen and (max-width: $xs) {
    flex-wrap: wrap;
    padding: 25px;
  }
  &__text {
    font-size: 2.2rem;
    line-height: 1.5;
    letter-spacing: -1px;
    max-width: 52.4rem;
    @media screen and (max-width: $xs) {
      text-align: center;
    }
  }
  &__box-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    @media screen and (max-width: $xs) {
      margin-top: 15px;
      justify-content: space-between;
    }
  }
  &__button {
    flex-shrink: 0;
    min-width: 14rem;
    &:first-of-type {
      margin-right: 15px;
    }
  }
}
</style>
