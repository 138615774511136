<template>
  <transition name="menu-nav__fade">
    <div class="menu-nav" v-show="menuActive">
      <div class="menu-nav__block-left">
        <div class="menu-nav__container">
          <div class="menu-nav__links">
            <router-link
              class="menu-nav__link text-link"
              v-for="(link, index) in links"
              :key="`link-${index}`"
              :class="{ 'menu-nav__link--active': link.active }"
              :to="link.to"
            >
              {{ link.title }}
            </router-link>
          </div>
          <div class="menu-nav__footer">
            <BaseButton
              class="menu-nav__footer-button button--outline-white"
              text="ARTTEXTSTYLE BLOG"
              @click="$router.push('/media/arttextstyle')"
            ></BaseButton>
            <BaseButton
              class="menu-nav__footer-button button--outline-white"
              text="JOIN OUR MAILING LIST"
              @click="$emit('showNewsletter')"
            ></BaseButton>
            <router-link
              class="menu-nav__footer-link text-link text-sub-title"
              to="/terms-and-conditions"
            >
              Terms of Service
            </router-link>
            <router-link
              class="menu-nav__footer-link text-link text-sub-title"
              to="/privacy-policy"
            >
              Privacy Policy
            </router-link>
          </div>
        </div>
      </div>
      <div class="menu-nav__block-right">
        <IconButton class="button--md button--outline-white" @click="$emit('hidenMenu')">
          <IconClose width="18" height="18"></IconClose>
        </IconButton>
        <div class="menu-nav__contacts">
          <a class="menu-nav__contacts-btn" href="tel:12038340623">
            <IconPhone></IconPhone>
          </a>
          <a class="menu-nav__contacts-btn" href="mailto:art@browngrotta.com">
            <IconEnvelope></IconEnvelope>
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconClose from "@/components/icons/IconClose.vue";
import IconEnvelope from "@/components/icons/IconEnvelope.vue";
import IconPhone from "@/components/icons/IconPhone.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: {
    IconButton,
    IconClose,
    IconEnvelope,
    IconPhone,
    BaseButton,
  },
  props: {
    menuActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      links: [
        {
          active: false,
          to: "/artists",
          title: "Artists",
        },
        {
          active: false,
          to: "/events",
          title: "Events",
        },
        {
          active: false,
          to: "/artworks",
          title: "Artworks",
        },
        {
          active: false,
          to: "/shop",
          title: "Shop",
        },
        {
          active: false,
          to: "/art-advisory",
          title: "Art Advisory",
        },
        {
          active: false,
          to: "/media",
          title: "Media",
        },
        {
          active: false,
          to: "/about",
          title: "About",
        },
        {
          active: false,
          to: "/contact",
          title: "Contact",
        },
      ],
    };
  },
  watch: {
    "$route.path": {
      handler: function (val) {
        this.links.forEach(link => {
          link.active = false;
          if (val === link.to) link.active = true;
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-nav {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background: rgba($color: $lightBlack, $alpha: 0.1);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(8px);
  }
  @supports not (backdrop-filter: none) {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: $lightBlack, $alpha: 0.3);
      filter: blur(8px);
      z-index: -1;
    }
  }
  &__fade {
    &-enter-active,
    &-leave-active {
      transition: all 1s ease;
    }
    &-enter {
      opacity: 0;
      .menu-nav__block-left,
      .menu-nav__block-right {
        transform: translateX(100%);
      }
      &-to {
        opacity: 1;
        .menu-nav__block-left,
        .menu-nav__block-right {
          transform: translateX(0%);
        }
      }
    }
    &-leave {
      opacity: 1;
      .menu-nav__block-left,
      .menu-nav__block-right {
        transform: translateX(0%);
      }
      &-to {
        opacity: 0;
        .menu-nav__block-left,
        .menu-nav__block-right {
          transform: translateX(100%);
        }
      }
    }
  }
  &__block-left {
    display: flex;
    background-color: rgba($color: $lightBlack, $alpha: 0.78);
    border-right: 0.6px solid $white;
    padding: 11rem 9rem 4rem 10.5rem;
    transition: all 1s ease;
    @media screen and (max-width: $xxs) {
      width: 100%;
      padding: 5rem 5rem 5.5rem 5.5rem;
    }
    @media screen and (max-width: $xxxs) {
      padding: 5rem 25px 5.5rem 5.5rem;
    }
    @media screen and (max-width: $xxxs) {
      padding: 5rem 15px 5.5rem 5.5rem;
    }
  }
  &__block-right {
    background-color: rgba($color: $lightBlack, $alpha: 0.78);
    padding: 4rem 3rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    transition: all 1s ease;
    @media screen and (max-width: $xxs) {
      padding: 4rem 25px 5.5rem;
    }
    @media screen and (max-width: $xxxs) {
      padding: 4rem 15px 5.5rem 15px;
    }
  }
  &__contacts {
    display: flex;
    flex-direction: column;
    &-btn {
      width: 5.2rem;
      height: 5.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid $white;
      background-color: rgba($color: $white, $alpha: 0.2);
      margin-top: 15px;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__links {
    display: flex;
    flex-direction: column;
  }
  &__link {
    position: relative;
    margin-bottom: 15px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -10.5rem;
      transform: translate(0, -50%);
      height: 3px;
      width: 0;
      background: $white;
      transition: all 0.3s ease;
      @media screen and (max-width: $xxs) {
        left: -5.5rem;
      }
    }
    &--active {
      &::before {
        width: 8rem;
        @media screen and (max-width: $xxs) {
          width: 5rem;
        }
      }
    }
  }
  &__footer {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    &-button {
      width: 19rem;
      padding: 0 10px;
      margin-bottom: 20px;
    }
    &-link {
      margin-bottom: 5px;
    }
  }
}
</style>

