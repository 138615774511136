<template>
  <div class="share-button" @click="isVisible = !isVisible" v-click-outside="hideVisible">
    <IconShare class="share-button__button-svg" />
    <ul class="share-button__list" :class="{ visible: isVisible }">
      <li class="share-button__list__item" @click="copyToClipboard">Copy link</li>

      <ShareNetwork
        class="share-button__list__item"
        network="facebook"
        :url="url"
        :title="data.title"
        :description="data.description"
      >
        <li>Facebook</li>
      </ShareNetwork>

      <a
        class="share-button__list__item"
        target="_blank"
        href="https://www.instagram.com/browngrottaarts/"
      >
        <li>Instagram</li>
      </a>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
import IconShare from "@/components/icons/IconShare.vue";

export default {
  components: { IconShare },

  data() {
    return {
      isVisible: false,
      url: `${window.location}`,
      currentDomain: `${process.env.VUE_APP_BACKEND_URL}`,
    };
  },

  computed: {
    ...mapGetters("publicapi/share", {
      data: "getShareData",
    }),
  },

  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.url);
      this.$toasted.show("Сopied!", {
        duration: 3000,
        type: "success",
      });
    },

    hideVisible() {
      this.isVisible = false;
    },
  },

  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.share-button {
  position: relative;
  height: 52px;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $white;
  background-color: rgba($color: $white, $alpha: 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
  @media screen and (max-width: $xs) {
    height: 42px;
    width: 42px;
  }
  &:hover {
    background-color: rgba($color: $white, $alpha: 0.3);
  }

  &__button-svg {
    @media screen and (max-width: $xs) {
      height: 18px;
      width: 17px;
    }
  }

  &__list {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    position: absolute;
    right: 0;
    bottom: -136px;
    width: 19.7rem;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    border: 1px solid #a2a2a2;
    padding: 1.3rem 2rem 1.8rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
    text-transform: uppercase;
    background: $lightBlack;
    //background: rgba($color: $lightBlack, $alpha: 0.85);

    &.visible {
      opacity: 1;
      visibility: visible;
      z-index: 3;
    }

    &__item {
      margin-bottom: 6px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
