<template>
  <footer class="footer" v-if="footer">
    <div class="footer__block-text">
      <router-link class="footer__text" to="/terms-and-conditions">terms & conditions</router-link>
      <router-link class="footer__text" to="/privacy-policy">privacy policy</router-link>
      <router-link class="footer__text" to="/shipping-and-returns">shipping & returns</router-link>
      <router-link class="footer__text" to="/contact">contact</router-link>
      <p class="footer__text">
        powered by
        <a data-v-35fb400b="" href="https://www.collekt.art/" target="_blank"> Collekt</a>
      </p>
    </div>
    <div class="footer__bottom">
      <Copyright class="footer__copyright" :hiddenText="false">
        browngrotta arts 2023 / photo © Tom Grotta
      </Copyright>
      <div class="footer__social">
        <a
          class="footer__social-link"
          href="https://www.facebook.com/browngrottaarts/"
          target="_blank"
        >
          <IconFacebook class="footer__social-icon" />
        </a>
        <!-- <a
          class="footer__social-link"
          href="https://www.artsper.com/us/art-galleries/united-states/7707/browngrotta-arts"
          target="_blank"
        >
          <IconArtsper class="footer__social-icon" />
        </a> -->
        <a
          class="footer__social-link"
          href="https://www.1stdibs.com/dealers/browngrotta-arts/"
          target="_blank"
        >
          <Icon1stDibs class="footer__social-icon" />
        </a>
        <a
          class="footer__social-link"
          href="https://www.instagram.com/browngrottaarts/"
          target="_blank"
        >
          <IconInstagram class="footer__social-icon" />
        </a>
        <a class="footer__social-link" href="https://twitter.com/BrowngrottaArts" target="_blank">
          <IconTwitter class="footer__social-icon" />
        </a>
        <a
          class="footer__social-link"
          href="https://www.artsy.net/partner/browngrotta-arts"
          target="_blank"
        >
          <IconArtsy class="footer__social-icon" />
        </a>
        <a
          class="footer__social-link"
          href="https://www.youtube.com/channel/UCV7IJwPD34cF-U88hB5e4aw"
          target="_blank"
        >
          <IconYoutube class="footer__social-icon" />
        </a>
        <a class="footer__social-link" href="https://arttextstyle.com/" target="_blank">
          <IconArttextstyle class="footer__social-icon" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import Copyright from "@/components/Copyright.vue";
import IconYoutube from "@/components/icons/IconYoutube.vue";
import IconTwitter from "@/components/icons/IconTwitter.vue";
import IconInstagram from "@/components/icons/IconInstagram.vue";
import IconFacebook from "@/components/icons/IconFacebook.vue";
import IconArtsy from "@/components/icons/IconArtsy.vue";
import IconArtsper from "@/components/icons/IconArtsper.vue";
import Icon1stDibs from "@/components/icons/Icon1stDibs.vue";
import IconArttextstyle from "@/components/icons/IconArttextstyleCircle.vue";

export default {
  components: {
    Copyright,
    IconYoutube,
    IconTwitter,
    IconInstagram,
    IconFacebook,
    IconArtsy,
    IconArtsper,
    Icon1stDibs,
    IconArttextstyle,
  },
  props: {
    footer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  width: 100%;
  padding: 2rem 10.5rem 15px;
  background-color: $lightBlack;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: $md) {
    padding: 2rem 5rem 15px;
  }
  @media only screen and (max-width: $sm) {
    padding: 2rem 25px 15px;
  }
  @media only screen and (max-width: $xs) {
    padding: 40px 25px 30px;
  }
  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0 0;
    @media screen and (max-width: $xxs) {
      padding-top: 30px;
    }
  }
  &__copyright {
    margin-right: 20px;
    margin-bottom: 20px;
    @media only screen and (max-width: $sm) {
      width: 100%;
      margin-right: 0;
      text-align: center;
    }
    @media screen and (max-width: $xxs) {
      margin-bottom: 40px;
      text-align: left;
    }
  }
  &__block-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    @media only screen and (max-width: $sm) {
      justify-content: space-between;
    }
    @media screen and (max-width: $xxs) {
      justify-content: flex-start;
    }
  }
  &__text {
    font-family: $fontFrutigerNeue;
    color: $white;
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 1.4;
    text-decoration: none;
    margin-right: 5rem;
    margin-bottom: 10px;
    @media only screen and (max-width: $sm) {
      display: inline-block;
      padding: 0 15px;
      margin-right: 0;
      text-align: center;
    }
    @media screen and (max-width: $xxs) {
      width: 100%;
      margin: 0 0 17px;
      padding: 0;
      text-align: left;
    }
  }
  &__social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media only screen and (max-width: $sm) {
      width: 100%;
      margin-bottom: 0;
      justify-content: center;
    }
    @media screen and (max-width: $xxs) {
      justify-content: space-between;
    }
    &-link {
      width: 4.2rem;
      height: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: $white, $alpha: 0.2);
      border-radius: 50%;
      border: 0.6px solid $white;
      padding: 10px;
      flex-shrink: 0;
      margin-left: 30px;
      @media screen and (min-width: $xxxl) {
        width: 4.6rem;
        height: 4.6rem;
      }
      @media screen and (max-width: $xs) {
        width: 3.6rem;
        height: 3.6rem;
        margin-left: 20px;
      }
      @media screen and (max-width: $xxxs) {
        margin-left: 10px;
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
    &-icon {
      height: 100%;
      max-width: 100%;
      width: auto;
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .footer {
    padding-bottom: 90px;
  }
}
</style>

