const Services = () => import("@/views/public/services/Services.vue");
const ArtAdvisory = () => import("@/views/public/services/ArtAdvisory.vue");
const PresentationAssistanceInquire = () =>
  import("@/views/public/services/PresentationAssistanceInquire.vue");
const VirtualPlacementInquire = () => import("@/views/public/services/VirtualPlacementInquire.vue");
const CommissionsInquire = () => import("@/views/public/services/CommissionsInquire.vue");

export default [
  {
    path: "/art-advisory",
    name: "ServicesPublic",
    component: Services,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/art-advisory/:slug",
    name: "ArtAdvisory",
    component: ArtAdvisory,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/art-advisory/virtual-placement/inquire",
    name: "VirtualPlacementInquire",
    component: VirtualPlacementInquire,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/art-advisory/commissions/inquire",
    name: "CommissionsInquire",
    component: CommissionsInquire,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/art-advisory/presentation-assistance/inquire",
    name: "PresentationAssistanceInquire",
    component: PresentationAssistanceInquire,
    meta: {
      layout: "base",
    },
  },
];

