import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    artAdvisories: [],
    artAdvisoriesHeaders: [
      {
        key: "title",
        title: "Title",
        sortable: true,
        className: ["bold"],
      },
      {
        key: "indent",
        className: ["indent"],
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: ["xsm", "center"],
        variables: "icon",
        icon: "IconEdit",
      },
      // {
      //   key: "delete",
      //   title: "Delete",
      //   sortable: false,
      //   className: ["xsm", "center"],
      //   variables: "icon",
      //   icon: "IconDelete",
      // },
    ],
    artAdvisory: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: {
      slug: null,
      title: null,
    },
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getArtAdvisories(state) {
      return state.artAdvisories;
    },
    getArtAdvisoriesHeaders(state) {
      return state.artAdvisoriesHeaders;
    },
    getArtAdvisory(state) {
      return state.artAdvisory;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setArtAdvisories(state, payload) {
      let artAdvisories = payload;
      if (state.page > 1) {
        state.artAdvisories = state.artAdvisories.concat(artAdvisories);
      } else {
        state.artAdvisories = artAdvisories;
      }
    },
    setArtAdvisory(state, payload) {
      state.artAdvisory = payload;
    },
    initialArtAdvisory(state) {
      state.artAdvisory = null;
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          slug: payload,
          title: payload,
        };
    },
  },
  actions: {
    async apiGetArtAdvisories(
      { commit },
      { sort = "title", order = "asc", page = "1", search = "" }
    ) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      await Vue.axios
        .get(`/api/admin/art-advisories?sort=${sort}&order=${order}&page=${page}&search=${search}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.hasMorePages);
          commit("setArtAdvisories", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiPostArtAdvisory({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = {
        success: false,
        error: null,
      };
      await Vue.axios
        .post("/api/admin/art-advisory", payload)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          Vue.toasted.show("Art Advisory successfully created", {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          if (e.response.status === 422) {
            if (e.response.data?.detail?.title[0]) {
              result.error = "The Art Advisory has already been taken";
              commit("setError", "The Art Advisory has already been taken");
            }
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetArtAdvisory({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/art-advisory/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setArtAdvisory", data.response);
        });
      return result;
    },
    async apiPutArtAdvisory({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      await Vue.axios
        .put(`/api/admin/art-advisory/${payload.id}`, payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          Vue.toasted.show("Art Advisory successfully updated", {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            if (response.data?.detail?.title[0]) {
              commit("setError", "The Art Advisory has already been taken");
            }
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteArtAdvisory({ commit }, id) {
      let result = false;
      await Vue.axios
        .delete(`/api/admin/art-advisory/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "error",
          });
        });
      return result;
    },
  },
};

