import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    items: [],
    itemsHeaders: [
      {
        key: "title",
        title: "Title",
        sortable: true,
        className: ["bold"],
      },
      {
        key: "type",
        title: "Type",
        sortable: true,
        variables: "tag",
      },
      {
        key: "reorder",
        title: "Reorder",
        sortable: false,
        className: ["s", "center"],
        variables: "icon",
        icon: "IconReorder",
      },
      {
        key: "edit",
        title: "Edit",
        sortable: false,
        className: ["xsm", "center"],
        variables: "icon",
        icon: "IconEdit",
      },
      {
        key: "delete",
        title: "Delete",
        sortable: false,
        className: ["xsm", "center"],
        variables: "icon",
        icon: "IconDelete",
      },
    ],
    searchedItems: [],
    item: null,
    changesLoading: false,
    hasMorePages: false,
    page: 1,
    serverError: {
      title: null,
    },
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getItemsHeaders(state) {
      return state.itemsHeaders;
    },
    getItems(state) {
      return state.items;
    },
    getItem(state) {
      return state.item;
    },
    getChangesLoading(state) {
      return state.changesLoading;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getServerError(state) {
      return state.serverError;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setItems(state, payload) {
      let items = [];
      items = payload.map(el => {
        return {
          ...el,
          orderType: el.type,
          type: { main: el.type, additional: [] },
        };
      });
      if (state.page > 1) {
        state.items = state.items.concat(items);
      } else {
        state.items = items;
      }
    },
    initialItem(state) {
      state.item = null;
    },
    setItem(state, payload) {
      state.item = payload;
    },
    setChangesLoading(state, payload) {
      state.changesLoading = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setError(state, payload) {
      let serverError = state.serverError;
      if (payload != null) {
        for (let i in payload) {
          serverError[i] = payload[i][0];
        }
        state.serverError = serverError;
      } else
        state.serverError = {
          title: payload,
        };
    },
  },
  actions: {
    async apiGetLooseThreads(
      { commit },
      {
        status = "",
        types = "",
        sort = "title",
        order = "asc",
        page = "1",
        search = "",
        search_type = "",
        limit = "",
      }
    ) {
      let success = false;
      commit("setLoading", true);
      commit("setHasMorePages", false);
      let query = {
        status,
        types,
        sort,
        order,
        page,
        search,
        search_type,
        limit,
      };
      let queryStr = "";
      for (let key in query) {
        if (query[key] != "") {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios
        .get(`/api/admin/loose-threads?${queryStr}`)
        .then(res => res.data)
        .then(data => {
          success = true;
          commit("setPage", page);
          commit("setHasMorePages", data.hasMorePages);
          commit("setItems", data.response);
        })
        .finally(() => {
          commit("setLoading", false);
        });
      return success;
    },
    async apiPostLooseThread({ commit }, payload) {
      let result = false;
      let data = {
        ...payload,
        status: payload.status != null ? payload.status : "0",
      };
      commit("setChangesLoading", true);
      await Vue.axios
        .post("/api/admin/loose-thread", data)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiGetLooseThread({ commit }, id) {
      let result = false;
      await Vue.axios
        .get(`/api/admin/loose-thread/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setItem", data.response);
        });
      return result;
    },
    async apiPutLooseThread({ commit }, payload) {
      commit("setChangesLoading", true);
      let result = false;
      await Vue.axios
        .put(`/api/admin/loose-thread/${payload.id}`, payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          commit("setError", null);
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch(e => {
          let response = e.response;
          if (response.status === 422) {
            commit("setError", response.data.detail);
          }
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
    async apiDeleteLooseThread({ commit }, id) {
      let result = false;
      await Vue.axios
        .delete(`/api/admin/loose-thread/${id}`)
        .then(res => res.data)
        .then(data => {
          result = true;
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        });
      return result;
    },
    async apiPutChoseOrder({ commit }, payload) {
      let list = [...payload];
      let result = false;
      commit("setChangesLoading", true);
      await Vue.axios
        .put(`/api/admin/loose-threads-order`, { list })
        .then(res => res.data)
        .then(data => {
          result = true;
          //commit('setPage', 1)
          //commit('setHasMorePages', data.response.hasMorePages)
          //commit('setItems', data.response.data)
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .finally(() => {
          commit("setChangesLoading", false);
        });
      return result;
    },
  },
};
