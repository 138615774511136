import home from "./home";
import artists from "./artists";
import contact from "./contact";
import news from "./news";
import pressreleases from "./pressreleases";
import exhibitions from "./exhibitions";
import shop from "./shop";
import filters from "./filters";
import search from "./search";
import events from "./events";
import blogs from "./blogs";
import artworks from "./artworks";
import services from "./services";
import memoriams from "./memoriams";
import looseThreads from "./looseThreads";
import share from "./share";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    home,
    artists,
    contact,
    news,
    pressreleases,
    exhibitions,
    shop,
    filters,
    search,
    events,
    blogs,
    artworks,
    services,
    memoriams,
    looseThreads,
    share,
  },
};

