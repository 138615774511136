import Vue from 'vue'
import { initials } from "@/helper";
export default {
    namespaced: true,
    state: {
        user: {}
    },
    getters: { getUserInfo(state) { return state.user } },
    mutations: {
        setUserInfo(state, payload) {
            state.user = {
                ...payload,
                initials: initials(payload.first_name, payload.last_name),
                name_abbreviation: `${payload.first_name} ${initials(payload.last_name)}.`
            }
        },
    },
    actions: {
        async apiGetUserInfo({ commit }) {
            let result = false
            try {
                await Vue.axios.get(`/api/admin/user`,)
                    .then((res) => {
                        result = true
                        commit('setUserInfo', res.data?.response)
                    })
                return result
            } catch (e) { console.log(e) }
        },
        async apiPutUserInfo({ commit }, payload) {
            let result = false;

            await Vue.axios.put(`/api/admin/user`, payload)
                .then((res) => res.data)
                .then((data) => {
                    result = true;
                    commit('setUserInfo', data.response)
                    Vue.toasted.show(data.message, {
                        duration: 2000,
                        type: "success",
                    });
                })
            return result
        },

    }
}