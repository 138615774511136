export function initAccessWidget() {
  let isPath =
    window.location.pathname.includes("admin") || window.location.pathname.includes("login");
  if (!isPath) {
    let s = document.createElement("script");
    s.src = "https://acsbapp.com/apps/app/dist/js/app.js";
    s.async = true;
    s.onload = function () {
      acsbJS.init({
        statementLink: "",
        footerHtml: "",
        hideMobile: false,
        hideTrigger: false,
        disableBgProcess: false,
        language: "en",
        position: "left",
        leadColor: "#000000",
        triggerColor: "#000000",
        triggerRadius: "50%",
        triggerPositionX: "right",
        triggerPositionY: "bottom",
        triggerIcon: "people",
        triggerSize: "medium",
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: "small",
          triggerPositionX: "right",
          triggerPositionY: "bottom",
          triggerOffsetX: 10,
          triggerOffsetY: 0,
          triggerRadius: "50%",
        },
      });
    };
    document.body.appendChild(s);
  }
}

export function destroyAccessWidget() {
  if (window.acsbJS) acsbJS.destroy();
  else console.error("acsbJS:", window.acsbJS);
}
