const Shop = () => import("@/views/public/shop/Shop.vue");
const ShopArtPiece = () => import("@/views/public/shop/ShopArtPiece.vue");
const ShopItemInquire = () => import("@/views/public/shop/ShopItemInquire.vue");
const ShopItemVirtualPlacement = () => import("@/views/public/shop/ShopItemVirtualPlacement.vue");

export default [
  {
    path: "/shop",
    meta: {
      layout: "base",
    },
    beforeEnter: (to, from, next) => {
      if (to.path === "/shop") {
        location.href = "https://store.browngrotta.com/";
      } else next();
    },
  },
  {
    path: "/artworks",
    name: "ShopPublic",
    component: Shop,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/artworks/:slug",
    name: "ShopArtPiece",
    component: ShopArtPiece,
    meta: {
      layout: "base",
      shareButton: true,
    },
  },
  {
    path: "/artworks/:slug/inquire",
    name: "ShopItemInquire",
    component: ShopItemInquire,
    meta: {
      layout: "base",
      menu: false,
    },
  },
  {
    path: "/artworks/:slug/virtual-placement",
    name: "ShopItemVirtualPlacement",
    component: ShopItemVirtualPlacement,
    meta: {
      layout: "base",
      menu: false,
    },
  },
];
