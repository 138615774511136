import Vue from "vue";
import router from "@/router";
import store from "@/store";
export default {
  namespaced: true,
  state: {
    loading: false,
    access: {
      access_token: null,
      expires_in: null,
    },
    error: null
  },
  getters: {
    isAuthenticated(state) { return state.access; },
    getLoading(state) { return state.loading; },
    getError(state) { return state.error }
  },
  mutations: {
    setToken(state, { access_token, expires_in }) {
      Vue.$cookies.set("BGAtoken", access_token, expires_in);
      state.token = { access_token, expires_in };
      if (access_token === null) {
        store.commit('setAdminLoader', true, { root: true });
        router.push({ path: '/login' })
      } else {
        router.push("/admin");
      }
    },
    changeLoader(state, payload) {
      state.loading = payload;
    },
    setError(state, payload) { state.error = payload }
  },
  actions: {
    async registerUser({ commit }, payload) {
      commit("changeLoader", true);
      let result = false;
      await Vue.axios
        .post("/api/user/confirm-invite", payload)
        .then(res => res.data)
        .then(data => {
          result = true;
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
          router.push({ path: '/login' })
        })
        .catch(e => {
          let error = e.response.data.detail?.email[0] ? e.response.data.detail.email[0] : e.response.data.message;
          Vue.toasted.show(error, {
            duration: 2000,
            type: "error",
          });
          router.push({ path: '/login' })
        })
        .finally(() => {
          commit("changeLoader", false);
        });
      return result;
    },
    async apiLoginUser({ commit }, payload) {
      commit("changeLoader", true);
      let result = {
        success: false,
        error: null,
      };
      await Vue.axios
        .post("/api/login", payload)
        .then(res => res.data)
        .then(data => {
          result.success = true;
          commit("setToken", data);
        })
        .catch(e => {
          if (e.response.status === 400) {
            result.error = e.response.data.detail.message;
          }
          if (e.response.status === 422) {
            result.error = e.response.data.detail.password
              ? e.response.data.detail.password[0]
              : e.response.data.detail.username ? e.response.data.detail.username[0] : "Some Error";
          }
        })
        .finally(() => {
          commit("changeLoader", false);
        });
      return result;
    },
    async apiLogoutUser({ commit }) {
      await Vue.axios
        .post("/api/logout")
        .then(res => res.data)
        .then(data => {
          commit("setToken", {
            access_token: null,
            expires_in: null,
          });
        });
    },
    async apiSendForgot({ commit }, payload) {
      let result = false
      await Vue.axios
        .post("/api/public/forgot-password", payload)
        .then(res => res.data)
        .then(data => {
          result = true
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch((e) => {
          let error = e.response.data.detail?.email[0] ? e.response.data.detail.email[0] : e.response.data.message;
          commit('setError', error)
        })
      return result
    },
    async apiResetPassword({ commit }, payload) {
      let result = false
      await Vue.axios
        .post("/api/public/reset-password", payload)
        .then(res => res.data)
        .then(data => {
          result = true
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
          commit("setToken", {
            access_token: null,
            expires_in: null,
          });

          commit('setError', null)
        })
        .catch((e) => {
          commit('setError', e.response.data.message)
        })
      return result
    },


    async apiSendVerify({ commit }, payload) {
      await Vue.axios
        .post("/api/admin/user/send-verify-email", payload)
        .then(res => res.data)
        .then(data => {
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        });
    },
    async apiVerifyEmail({ commit }, payload) {
      await Vue.axios
        .put("/api/admin/user/verify-email", payload)
        .then(res => res.data)
        .then(data => {
          commit("setToken", {
            access_token: null,
            expires_in: null,
          });
          Vue.toasted.show(data.message, {
            duration: 2000,
            type: "success",
          });
        })
        .catch((e) => {
          Vue.toasted.show(e.response.data.message, {
            duration: 2000,
            type: "error",
          });
          commit("setToken", {
            access_token: null,
            expires_in: null,
          });
        })
    },
  },
};
