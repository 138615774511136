<template>
  <button class="button" @click.prevent="$emit('click')" :disabled="disabled">
    {{ text }}
    <span
      class="loader"
      :style="`border: 2px solid ${textColor}; border-bottom-color: transparent`"
      v-if="loading"
    ></span>
  </button>
</template>
<script>
export default {
  props: {
    text: { type: String },
    loading: { type: Boolean, default: false },
    textColor: { type: String, default: "#fff" },
    disabled: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.8rem;
  width: max-content;
  border: 1px solid transparent;
  border-radius: 4px;
  font-family: $fontFrutiger;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: -0.4px;
  padding: 0 30px;
  transition: all 0.3s ease;
  @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
  }
  @media screen and (max-width: $xxs) {
    padding: 0 20px;
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.7;
  }
  .loader {
    display: inline-block;
    margin-left: 10px;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    height: 20px;
    width: 20px;
    max-width: 20px;
    max-height: 20px;
    min-height: 20px;
    min-width: 20px;
    background: transparent !important;
    -webkit-animation: rotate 0.75s 0s linear infinite;
    animation: rotate 0.75s 0s linear infinite;
  }
  &--admin {
    height: 7rem;
    @include type($fs: 14px, $lh: 1.36, $fw: 700);
    text-transform: uppercase;
    border-radius: 12px;
  }
  &--public {
    min-width: 180px;
    @include publictextNeue($fw: 500);
    &-bold {
      @include publictextNeue($fs: 13px, $fw: 700);
    }
    &-xs {
      height: 4.8rem;
      border-radius: 20px;
    }
  }
  &--black {
    background-color: $black;
    color: $white;
    &:hover {
      background-color: $orange;
    }
  }
  &--white {
    background-color: $white;
    color: $black;
    &:hover {
      background-color: $light2Grey;
    }
  }
  &--green {
    background-color: $green;
    color: $white;
    &:hover {
      background-color: $orange;
    }
  }
  &--outline {
    background-color: transparent;
    color: $black;
    border-color: $black;
    &:hover {
      background-color: rgba($color: $black, $alpha: 0.1);
      color: $black;
    }
  }
  &--sm {
    max-width: max-content;
    min-width: 18rem;
    height: 5.5rem;
    @include type($fs: 12px);
    border-radius: 7px;
  }
  &--xs {
    width: 100%;
    min-width: 10.5rem;
    max-width: max-content;
    height: 5.2rem;
    @include type($fs: 12px);
    border-radius: 7px;
  }
  &--inline {
    height: auto;
    width: auto;
    @include type($fs: 14px, $lh: 1.5);
    color: $lightGrey;
    border-radius: 0;
    padding: 0;
  }
  &--lg {
    font-family: $fontFrutiger;
    height: 7rem;
    padding: 0 24px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.5;
    @media (max-width: $xxs) {
      height: 5.5rem;
      padding: 0 12px;
    }
  }
  &--full-width {
    width: 100%;
  }
  &--outline-white {
    background-color: transparent;
    color: $white;
    border-color: $white;
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.1);
      color: $white;
    }
  }
  &--uppercase {
    text-transform: uppercase;
  }

  &--only-text {
    width: auto;
    height: auto;
    border: none;
    color: $lightGrey;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &:hover {
      color: $blue;
    }
    &-underline {
      text-decoration: underline;
      font-size: 16px;
      line-height: 1.75;
      font-weight: 300;
      padding: 0;
      text-underline-offset: 2px;
      color: $white;
    }
  }
  &--not-found {
    font-family: $fontFrutiger;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.23;
    padding: 18px 30px;
    width: max-content;
    height: 5.2rem;
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.6);
    transform: rotate(180deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
</style>

