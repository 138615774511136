<template>
  <div
    class="form-control"
    :class="{ 'form-control--icon': icon, 'form-control--invalid': validateError }"
  >
    <div class="form-control_relative">
      <label class="form-control__label">
        {{ label }}
        <span class="form-control__label_required" v-if="required">*</span>
      </label>
      <input
        required
        :type="type"
        class="form-control__input"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        @change="changeInput"
        @input="changeInput"
      />
      <div v-if="icon" class="form-control__icon">
        <slot name="icon"></slot>
        <slot name="btn"></slot>
      </div>
    </div>
    <slot name="text"></slot>
    <p class="form-control__error">{{ validateError }}</p>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: [String, Number] },
    placeholder: { type: String },
    label: { type: String },
    type: { type: String, default: "text" },
    icon: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    isEventInput: { type: Boolean, default: false },
  },

  methods: {
    changeInput(event) {
      if (this.isEventInput) this.$emit("input", event.target.value);
      else if (event.type === "change") this.$emit("input", event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  margin-top: 10px;
  &_relative {
    position: relative;
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  &__label {
    @include type($ff: $fontFrutiger, $fs: 1.8rem, $fw: 400, $lh: 1.2, $ls: -0.27px);
    color: $white;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
    }
    &_required {
      font-size: 18px;
      font-weight: 600;
      color: $error;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      }
    }
  }
  &__input {
    height: 5.2rem;
    padding: 0 2rem;
    border: 0.5px solid $white;
    border-radius: 4px;
    @include type($ff: $fontFrutiger, $fs: 1.8rem, $fw: 400, $lh: 1.2);
    color: $white;
    background-color: rgba($color: $white, $alpha: 0.1);
    margin-top: 7px;
    &:-webkit-autofill {
      background-color: rgba(255, 255, 255, 1) !important;
      background-image: none !important;
      -webkit-box-shadow: 0 0 0px 10em white inset !important;
      -webkit-text-fill-color: $black !important;
    }
    &::placeholder {
      color: rgba($color: $white, $alpha: 0.7);
    }
    &:-ms-input-placeholder {
      color: rgba($color: $white, $alpha: 0.7);
    }
    &::-ms-input-placeholder {
      color: rgba($color: $white, $alpha: 0.7);
    }
    &:disabled {
      opacity: 0.6;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
    }
  }
  &__simple-text {
    display: inline-block;
    padding: 7px 0 0;
    @include type($fs: 13px, $fw: 600);
    color: $green;
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
    width: max-content;
  }
  &--inquire {
    .form-control__input {
      height: 4rem;
      padding: 0;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $grey;
      margin: 0;
      background: none;
      &:focus {
        border-bottom: 1px solid $white;
        &::placeholder {
          color: $white;
        }
        &:-ms-input-placeholder {
          color: $white;
        }
        &::-ms-input-placeholder {
          color: $white;
        }
      }
    }
  }
  &--icon {
    .form-control__input {
      padding-right: 7rem;
    }
    .form-control__label {
      width: calc(100% - 9.7rem);
    }
    &-15 {
      .form-control__input {
        padding-right: 15rem;
      }
    }
  }
  &--invalid {
    .form-control__input {
      border-color: $error;
    }
  }
  &--valid {
    .form-control__input {
      border-color: $green;
    }
  }
  &--additional {
    &.form-control {
      margin-top: 0;
    }
    .form-control__input {
      margin-top: 0;
      color: $white;
      font-size: 14px;
      line-height: 1.285;
      font-weight: 600;
      height: 4.8rem;
      min-width: max-content;
      width: 100%;
    }
    .form-control__icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
