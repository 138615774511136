<template>
  <div class="image" :style="{ backgroundImage: image ? `url(${image})` : '' }">
    <span v-if="!image">{{ initials }}</span>
    <label class="image__label" :for="`add-${id}`" v-if="editable">
      <input
        class="image__label_input"
        type="file"
        :name="`add${id}`"
        :id="`add-${id}`"
        @change="onAddChange({ element: $event })"
        ref="fileAdd"
        :accept="types"
        multiple
        :disabled="disabled"
      />
      <IconEdit width="19" height="18" viewBox="0 0 19 18" fill="black" />
    </label>
  </div>
</template>
<script>
import IconEdit from "@/components/icons/IconEdit.vue";
export default {
  props: {
    id: { type: [String, Number] },
    types: { type: String, default: "image/png,image/jpeg,image/jpg" },
    size: { type: [Number, String], default: "15" },
    image: { type: String },
    initials: { type: String },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
  components: { IconEdit },
  methods: {
    checkSize(val) {
      const size = parseFloat(val.size / (1024 * 1024)).toFixed(2);
      let correct = false;
      if (+size > +this.size) {
        this.$toasted.show(`Please select image ${val.name} size less than ${this.size} MB`, {
          duration: 3000,
          type: "error",
        });
      } else {
        correct = true;
      }
      return correct;
    },
    async onAddChange({ element }) {
      for (let el of element.target.files) {
        const size = this.checkSize(el);
        if (size) {
          this.$emit("upload", el);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: $blueDark;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  span {
    font-size: 2rem;
    font-weight: 600;
    color: $white;
  }
  &--lg {
    width: 13rem;
    height: 13rem;
    min-width: 13rem;
    min-height: 13rem;
    span {
      font-size: 6rem;
    }
  }
  &__label {
    position: absolute;
    bottom: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.4rem;
    width: 4.4rem;
    border-radius: 50%;
    box-shadow: 0 2px 9px rgba($color: #000000, $alpha: 0.25);
    background-color: $white;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: $light2Grey;
    }
    svg {
      width: 19px;
      height: 18px;
    }
    &_input {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -1;
      opacity: 0;
    }
  }
}
</style>