import Vue from "vue";
export default {
  namespaced: true,
  state: {
    acquisitionsList: [],
    hasMorePages: false,
  },
  getters: {
    getAcquisitionsList(state) {
      return state.acquisitionsList;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
  },
  mutations: {
    setAcquisitionsList(state, { data, page }) {
      if (page > 1) {
        state.acquisitionsList = state.acquisitionsList.concat(data);
      } else {
        state.acquisitionsList = data;
      }
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
  },
  actions: {
    async apiGetAcquisitionsList({ commit }, page) {
      await Vue.axios.get(`/api/public/artworks/acquisitions?page=${page}`).then(response => {
        commit("setAcquisitionsList", { data: response.data.response, page });
        commit("setHasMorePages", response.data.hasMorePages);
      });
    },
  },
};
