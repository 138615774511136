import Vue from "vue";
export default {
  namespaced: true,
  state: {
    eventsList: [],
    hasMorePages: false,
    featuredEvent: {},
    filters: [],
    specificEvent: {
      sponsors: [],
    },
    associatedExhibition: {},
  },
  getters: {
    getEventsList(state) {
      return state.eventsList;
    },
    getSpecificEvent(state) {
      return state.specificEvent;
    },
    getAssociatedExhibition(state) {
      return state.associatedExhibition;
    },
    getHasMorePages(state) {
      return state.hasMorePages;
    },
    getFeaturedEvent(state) {
      return state.featuredEvent;
    },
    getFilters(state) {
      return state.filters;
    },
  },
  mutations: {
    setEventsList(state, { data, page }) {
      if (page > 1) {
        state.eventsList = state.eventsList.concat(data);
      } else {
        state.eventsList = data;
      }
    },
    setSpecificEvent(state, payload) {
      state.specificEvent = payload;
    },
    setAssociatedExhibition(state, payload) {
      state.associatedExhibition = payload;
    },
    setHasMorePages(state, payload) {
      state.hasMorePages = payload;
    },
    setFeaturedEvent(state, payload) {
      state.featuredEvent = payload;
    },
    setFilters(state, payload) {
      let filters = [
        {
          key: "",
          title: "ALL EVENTS",
          selected: true,
        },
      ];
      payload.forEach(el => {
        filters.push({ ...el, selected: el.selected ? el.selected : false });
      });
      state.filters = filters;
    },
    changeFilterValue(state, payload) {
      let filters = state.filters;
      filters.forEach(el => {
        el.selected = el.key === payload.key ? true : false;
      });
      state.filters = filters;
    },
  },
  actions: {
    async apiGetEventsList({ commit }, { sort = "", page = "1", events = "" }) {
      let query = {
        sort,
        page,
        events,
      };
      let queryStr = "";
      let success = false;
      for (let key in query) {
        if (query[key]) {
          queryStr += `${key}=${query[key]}&`;
        }
      }
      await Vue.axios.get(`/api/public/events/list?${queryStr}`).then(response => {
        success = true;
        commit("setEventsList", { data: response.data.response, page });
        commit("setHasMorePages", response?.data?.hasMorePages);
      });
      return success;
    },

    async apiGetFeaturedEvent({ commit }) {
      await Vue.axios
        .get("/api/public/featured-event")
        .then(res => res.data)
        .then(data => {
          commit("setFeaturedEvent", data?.response);
        });
    },

    async apiGetFiltersBlock({ commit }, { list, type }) {
      await Vue.axios
        .get(`/api/public/filters?list=${list}&type=${type}`)
        .then(res => res.data)
        .then(data => {
          commit("setFilters", data?.response[0]?.items);
        });
    },

    async apiGetSpecificEvent({ commit }, slug) {
      await Vue.axios.get(`/api/public/events/${slug}`).then(response => {
        const mockEvent = {
          ...response?.data?.response.event,
          sponsors: response?.data?.response.event.sponsors
            ? response?.data?.response.event.sponsors
            : [],
        };
        commit("setSpecificEvent", mockEvent ?? {});
        commit("setAssociatedExhibition", response?.data?.response.exhibition ?? {});
      });
    },
  },
};

