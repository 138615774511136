<template>
  <transition name="fade">
    <div class="dashboard">
      <Navigation></Navigation>
      <LoaderCircle v-if="loader"></LoaderCircle>
      <div class="dashboard__inner" v-else>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>
<script>
import Navigation from "@/components/Navigation.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import LoaderCircle from "@/components/LoaderCircle.vue";
export default {
  components: { Navigation, LoaderCircle },
  head: {
    title: function () {
      return {
        inner: "Admin browngrotta arts",
      };
    },
  },
  async created() {
    let result = await this.apiGetUserInfo();
    if (result) {
      this.setAdminLoader(false);
    }
  },
  computed: {
    ...mapGetters({ loader: "getAdminLoader" }),
  },
  methods: {
    ...mapActions("user", ["apiGetUserInfo"]),
    ...mapMutations(["setAdminLoader"]),
  },
};
</script>
<style lang="scss">
.dashboard {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  background: #fbfaff;
  overflow: hidden;
  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: 4rem 5rem 3rem 4rem;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 4;
    @media only screen and (max-width: $xl) {
      padding: 35px 30px 30px 20px;
    }
    &_body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease-in;
}
.fade-enter,
.fade-leave-to {
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

.page {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin-bottom: 15px;
      color: $black;
    }
    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-search {
      display: flex;
      align-items: center;
      width: 65rem;
      & > :first-child {
        margin-right: 10px;
      }
    }
  }
  &__filters {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 7px;
    .tag {
      margin-left: 1rem;
    }
  }
}
</style>
