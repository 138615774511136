const Artists = () => import("@/views/public/artists/Artists.vue");
const ArtistsList = () => import("@/views/public/artists/ArtistsList.vue");
const SpecificArtist = () => import("@/views/public/artists/SpecificArtist.vue");

export default [
  {
    path: "/artists",
    name: "ArtistsPublic",
    component: Artists,
    meta: {
      layout: "base",
    },
  },
  {
    path: "/artists/:slug",
    name: "SpecificArtistPublic",
    component: SpecificArtist,
    meta: {
      layout: "base",
      shareButton: true,
    },
  },
];
