const General = () => import("@/views/admin/General.vue");

const Dashboard = () => import("@/views/admin/Dashboard.vue");
const Profile = () => import("@/views/admin/Profile.vue");

const Collection = () => import("@/views/admin/collection/Collection.vue");
const Artworks = () => import("@/views/admin/collection/artworks/Artworks.vue");
const Artists = () => import("@/views/admin/collection/artists/Artists.vue");
const Publications = () => import("@/views/admin/collection/publications/Publications.vue");

const GeneralEvents = () => import("@/views/admin/events/General.vue");
const Exhibitions = () => import("@/views/admin/events/exhibitions/Exhibitions.vue");
const Events = () => import("@/views/admin/events/events/Events.vue");
const PrivateViewingRooms = () =>
  import("@/views/admin/events/privateViewingRooms/PrivateViewingRooms.vue");

const GeneralContent = () => import("@/views/admin/content/General.vue");
const InTheNews = () => import("@/views/admin/content/inTheNews/InTheNews.vue");
const PressReleases = () => import("@/views/admin/content/pressreleases/Pressreleases.vue");
const LooseThreads = () => import("@/views/admin/content/looseThreads/LooseThreads.vue");
const Memoriams = () => import("@/views/admin/content/memoriams/Memoriams.vue");
const Tags = () => import("@/views/admin/content/tags/Tags.vue");
const ArtAdvisories = () => import("@/views/admin/content/artAdvisory/ArtAdvisories.vue");

const GeneralArttextstyle = () => import("@/views/admin/arttextstyle/General.vue");
const Arttextstyle = () => import("@/views/admin/arttextstyle/arttextstyle/Arttextstyle.vue");
const Comments = () => import("@/views/admin/arttextstyle/comments/Comments.vue");

export default [
  {
    path: "/admin",
    component: General,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          layout: "admin",
        },
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: {
          layout: "admin",
        },
      },
      {
        path: "collection",
        redirect: "/admin/collection/artworks",
        name: "Collection",
        component: Collection,
        meta: {
          layout: "admin",
        },
        children: [
          {
            path: "artworks",
            name: "Artworks",
            component: Artworks,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "artists",
            name: "Artists",
            component: Artists,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "publications",
            name: "Publications",
            component: Publications,
            meta: {
              layout: "admin",
            },
          },
        ],
      },
      {
        path: "events",
        redirect: "/admin/events/exhibitions",
        name: "GeneralEvents",
        component: GeneralEvents,
        meta: {
          layout: "admin",
        },
        children: [
          {
            path: "exhibitions",
            name: "Exhibitions",
            component: Exhibitions,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "events",
            name: "Events",
            component: Events,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "private-viewing-rooms",
            name: "PrivateViewingRooms",
            component: PrivateViewingRooms,
            meta: {
              layout: "admin",
            },
          },
        ],
      },
      {
        path: "content",
        redirect: "/admin/content/in-the-news",
        name: "GeneralContent",
        component: GeneralContent,
        meta: {
          layout: "admin",
        },
        children: [
          {
            path: "in-the-news",
            name: "InTheNewsInTheNews",
            component: InTheNews,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "press-releases",
            name: "PressReleasesAdmin",
            component: PressReleases,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "lives-well-lived",
            name: "Memoriams",
            component: Memoriams,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "loose-threads",
            name: "LooseThreads",
            component: LooseThreads,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "art-advisory",
            name: "ArtAdvisories",
            component: ArtAdvisories,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "tags",
            name: "Tags",
            component: Tags,
            meta: {
              layout: "admin",
            },
          },
        ],
      },
      {
        path: "arttextstyle",
        redirect: "/admin/arttextstyle/arttextstyle",
        name: "GeneralArttextstyle",
        component: GeneralArttextstyle,
        meta: {
          layout: "admin",
        },
        children: [
          {
            path: "arttextstyle",
            name: "Arttextstyle",
            component: Arttextstyle,
            meta: {
              layout: "admin",
            },
          },
          {
            path: "comments",
            name: "Comments",
            component: Comments,
            meta: {
              layout: "admin",
            },
          },
        ],
      },
    ],
  },
];

