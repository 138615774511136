<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none">
    <g clip-path="url(#clip0_11_22)" :fill="color">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4745 12.465C19.1842 12.465 18.949 12.6917 18.949 12.9714V17.4678C18.948 18.3065 18.2429 18.9862 17.3725 18.987H2.6275C1.75714 18.9862 1.05203 18.3065 1.051 17.4678V4.27119C1.05203 3.43265 1.75714 2.75295 2.6275 2.75196H7.29337C7.58362 2.75196 7.81887 2.52526 7.81887 2.24555C7.81887 1.96603 7.58362 1.73914 7.29337 1.73914H2.6275C1.17704 1.74072 0.00164219 2.87342 0 4.27119V17.468C0.00164219 18.8657 1.17704 19.9984 2.6275 20H17.3725C18.823 19.9984 19.9984 18.8657 20 17.468V12.9714C20 12.6917 19.7648 12.465 19.4745 12.465V12.465Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8828 0.74231C16.8727 -0.247437 18.4775 -0.247437 19.4674 0.74231L20.1283 1.4032C21.1166 2.39362 21.1166 3.99695 20.1283 4.98737L10.0799 15.0353C10.0111 15.1041 9.92565 15.1538 9.83202 15.1798L5.06139 16.5013C4.86583 16.5554 4.65636 16.5002 4.51269 16.3568C4.36925 16.2131 4.31395 16.0037 4.36828 15.8081L5.68969 11.0379C5.71569 10.9442 5.76537 10.8588 5.83434 10.79L15.8828 0.74231ZM18.7345 4.78827L19.3319 4.19098C19.8808 3.64081 19.8808 2.75006 19.3319 2.19958L18.6708 1.53876C18.121 0.988953 17.2294 0.988953 16.6796 1.53876L16.082 2.13605L18.7345 4.78827ZM15.2856 2.93262L7.06164 11.1561L9.71374 13.8081L17.9379 5.58466L15.2856 2.93262V2.93262ZM6.53185 12.2191L8.65075 14.3381L5.71972 15.1501L6.53185 12.2191V12.2191Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_11_22">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 21 20" },
    width: { type: String, default: "21" },
    height: { type: String, default: "20" },
    color: { type: String },
  },
};
</script>