import { extend, localize, configure } from "vee-validate";
import { min, max, required, email, confirmed, numeric } from "vee-validate/dist/rules";

import en from "vee-validate/dist/locale/en.json";

// Install rules
extend("required", {
  ...required,
  message: "This field is required",
});

extend("limitNumber", {
  validate: value => {
    return value * 1 < 21474836;
  },
  message: "Please enter value less then 21 474 836",
});

extend("email", {
  ...email,
  message: "Invalid email",
});
extend("number", {
  validate(value) {
    const regex = RegExp("^([0-9])");
    return regex.test(value);
  },
  message: "Only numbers",
});
extend("login_email", {
  ...email,
  message: "The Email you’ve entered is incorrect",
});
extend("verify_password", {
  validate: value => {
    let strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[0-9])(?=.*[!\"#$%&'()*+,-./:;<=>?@^_`{|}~[])(?=.{8,50})"
    );
    if (strongRegex.test(value)) {
      return true;
    }
    return "Must have at least 8 characters, one number and one special character";
  },
});
extend("min", {
  validate(value, args) {
    let newArg = args ? args : 3;
    if (value.replace(/\s+/g, "").length >= newArg) {
      return true;
    }
    return `Min ${newArg} characters`;
  },
});
extend("max", {
  validate(value, args) {
    let newArg = args ? args : 200;
    if (value.length <= newArg) {
      return true;
    }
    return `Max ${newArg} characters`;
  },
});
extend("less_then", {
  validate(value, args) {
    if (args[0] === "") {
      return true;
    } else if (Number(value) >= Number(args)) {
      return true;
    }
    return `Value can’t be less then ${args}`;
  },
});
extend("more_then", {
  validate(value, args) {
    if (args[0] === "") {
      return true;
    }
    if (Number(value) <= Number(args)) {
      return true;
    }
    return `Value can’t be more then ${args}`;
  },
});
extend("confirmed", {
  ...confirmed,
  message: "Doesn't match",
});

extend("positive", value => {
  if (value >= 0) {
    return true;
  }
  return "This field must be a positive number";
});

extend("path", {
  validate: value => {
    const regex = RegExp(/^[a-zA-Z0-9-]+$/);
    return regex.test(value);
  },
  message: 'Invalid path. Only dashes, numbers and letters. Example "my-name"',
});

extend("url", {
  validate: value => {
    const regex = RegExp(
      /^(http(s)?):\/\/(www\.)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
    );
    return regex.test(value);
  },
  message: "Invalid url",
});

extend("phone_number", {
  message: "Invalid phone number",
  validate: value => {
    var strongRegex = new RegExp(/^1?\s?(\([0-9]{3}\)[- ]?|[0-9]{3}[- ]?)[0-9]{3}[- ]?[0-9]{4}$/);

    return strongRegex.test(value);
  },
});

extend("requiredSelect", {
  validate: value => {
    return value.title.length > 0;
  },
  message: "This field is required",
});

configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});

// Install messages
localize({
  en,
});
